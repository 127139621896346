import './Header.css';
import { useStateValue } from '../System/StateProvider';
import { Icon } from 'semantic-ui-react';
import { useAuthManager } from '../System/API';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolTip from './ToolTip';

export default function Header() {
    const [{user, hideSidebar, alertVolume}, dispatch] = useStateValue();
    //const [vol, setVol] = useState(alertVolume);
    const authManager = useAuthManager();
    const navigate = useNavigate();
    const tooltip = useRef(null);

    const signOut = (e) => {
        authManager.signOut();
        dispatch({ user: null, signedIn: false })
        navigate('/login', { replace: true });
    }

    const openSettings = (e) => {
        tooltip.current.closeTooltip();
        if (!hideSidebar) {
            dispatch({
                sidebarState: 'closed'
            })    
        }
        navigate('/settings/profile', { replace:true });
    }

    const toggleSidebar = () => {
        dispatch({ hideSidebar: !hideSidebar })
    }

    return (
        <div className='mainHeader transp'>
            <div className='headerMenuLeft'>
                <Icon className="sidebarIcon" name="bars" size='large' onClick={toggleSidebar}/>
                <h1 className="titleString">Bien<span id={"bolder"}>Chats</span>&nbsp;</h1>
            </div>
            <ToolTip 
                type='toggle'
                ref={tooltip}
                clickout={true}
                pivH='right'
                pivV='top'
                posH='right'
                posV='bottom'
                content={
                    <div className='headerDropdownMenuContent'>
                        <div className='dropdownMenuItem'>
                            <input 
                                type='range'
                                min={0}
                                label='Volume'
                                max={100}
                                value={alertVolume} 
                                onChange={e => {
                                    //setVol(e.target.value);
                                    dispatch({ alertVolume:parseInt(e.target.value) })
                                }}
                            />
                            <Icon 
                                name={alertVolume === 0 ? 'volume off':'volume up'}
                                onClickCapture={() => {
                                    //setVol(vol === 0 ? 100:0);
                                    dispatch({ alertVolume:parseInt(alertVolume === 0 ? 100:0) })
                                }} 
                                circular
                            />
                        </div>
                        <div onClick={openSettings} className='dropdownMenuItem menuItemButton'>
                            <h4>Settings</h4>
                        </div>
                        <div onClick={signOut} className='dropdownMenuItem menuItemButton'>
                            <h4>Log Out</h4>
                        </div>
                    </div>                    
                }
            >
                <div className='headerMenuRight'>
                    <img  
                        src={user && user.avatar}
                        width={35}
                        height={35}
                        className='commentAvatar'
                        alt='Avatar'
                    /> 
                </div>
            </ToolTip>
        </div>
    )
}
