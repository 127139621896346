import React, { useEffect, useRef, useState } from 'react'
import { createEditor, Editor, Transforms, Element as SlateElement, Text } from 'slate'
import { Slate, Editable, withReact, useSlate, useSlateSelection, ReactEditor } from 'slate-react'
import { ToolbarButton, ColorPicker, SelectDropdown } from './SlateToolbar'
import { Element, Leaf } from './SlateComponents'
import "./SlateEditor.css"

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
const urlRegex = /((https?:\/\/|www\.)([^\s]+))/g;
const imgExtnRegex = /\.(apng|avif|gif|jpg|jpeg|jfif|pjpeg|pjp|png|svg|webp|bmp)([^\s\w\d]*)\b/;
const fonts = [
    { displayVal: '--Default--', value: "Lato,Helvetica Neue,Arial,Helvetica,sans-serif", default: true },
    { value: "Arial" },
    { value: "Arial Black" },
    { value: "Comic Sans MS" },
    { value: "Courier New" },
    { value: "Georgia" },
    { value: "Impact" },
    { value: "Microsoft Sans Serif" },
    { value: "Tahoma" },
    { value: "Times New Roman" },
    { value: "Trebuchet MS" },
    { value: "Verdana" }
];
const sizes = [
    { value: '10px' },
    { value: '12px' },
    { value: '14px', default: true },
    { value: '16px' },
    { value: '18px' },
    { value: '20px' },
    { value: '22px' },
    { value: '24px' },
    { value: '28px' },
    { value: '32px' },
    { value: '40px' },
];

const getURLPositions = (text) => {
    const matches = text.match(urlRegex);
    const matchInfo = [];
    if (matches) {
        for (let match of matches) {
            const isImg = match.match(imgExtnRegex);
            matchInfo.push({
                type: isImg ? 'image' : 'link',
                offset: text.indexOf(match),
                length: match.length,
                url: match
            })
        }
    }
    return matchInfo
}

const formatState = (state) => {
    const children = [];
    for (let child of state) {
        const childObj = {};
        const keys = Object.keys(child);
        for (let key of keys) {
            if (key.includes('$style_')) {
                if (!childObj.style) childObj.style = {};
                childObj.style[key.split('$style_')[1]] = child[key];
            } else if (key === 'children' && child.type !== 'image') {
                childObj.children = formatState(child.children)
            } else if (key === 'url' || key === 'text') {
                childObj[key] = encodeURIComponent(child[key])
            } else {
                childObj[key] = child[key]
            }
        }
        children.push(childObj)
    }
    return children
}

export default function SlateEditor({ richTextMenu, changeVal, sendMsg, setApi, setRightMenu, rightMenu }) {
    const [editor] = useState(() => withReact(createEditor()));

    const handleStateChange = (change) => {
        const formattedMessage = formatState(change);
        changeVal(formattedMessage)
        
        const nodes = Array.from(Editor.nodes(editor, {
            match: node => {
                return Text.isText(node) &&
                    node.type !== 'image' &&
                    node.type !== 'link'
            }
        }))

        if (nodes.length) {
            const urls = getURLPositions(nodes[0][0].text);
            if (urls.length) {
                const anchor = { path: nodes[0][1], offset: urls[0].offset };
                const focus = { path: nodes[0][1], offset: urls[0].offset + urls[0].length }

                Transforms.delete(editor, {
                    at: { anchor: anchor, focus: focus }
                })

                switch (urls[0].type) {
                    case 'link':
                        Transforms.insertFragment(editor, [
                            { text: urls[0].url, type: 'link' }
                        ])
                        break
                    case 'image':
                        if (nodes[0][0].text === urls[0].url) {
                            Transforms.removeNodes(editor, {
                                at: { anchor: anchor, focus: focus }
                            })
                        }
                        Transforms.insertNodes(editor, [
                            { type: 'image', url: urls[0].url, children: [{ text: '' }] },
                            { type: 'p', children: [{ text: '' }] }
                        ], {})
                }
            }
        }
    }

    const handleKeyDown = (event) => {
        if (!event.ctrlKey) {
            if (event.code === 'Space' && getMarkValue(editor, 'type') === 'link') {
                Editor.removeMark(editor, 'type');
            }
            switch (event.key) {
                case 'Enter':
                    if (!event.shiftKey) {
                        event.preventDefault();
                        sendMsg();
                        api.clearTextbox();
                    } else if (getMarkValue(editor, 'type') === 'link') {
                        Editor.removeMark(editor, 'type');
                    }
                    break
                default:
                    break
            }
        } else {
            switch (event.key) {
                case '`': {
                    event.preventDefault()
                    toggleBlock(editor, 'code');
                    break
                }
                case 'b': {
                    event.preventDefault()
                    setMark(editor, '$style_fontWeight', 'bold');
                    break
                }
                case 'i': {
                    event.preventDefault();
                    setMark(editor, '$style_fontStyle', 'italic');
                    break
                }
                case 'u': {
                    event.preventDefault();
                    setMark(editor, '$style_textDecoration', 'underline');
                    break
                }
                case 's': {
                    event.preventDefault();
                    setMark(editor, '$style_textDecoration', 'line-through');
                    break
                }
                default:
                    break
            }
        }
    }

    const api = {
        insertGif: (url) => {
            Transforms.insertNodes(editor, [
                { type: 'image', url:url, children: [{ text: '' }] },
                { type: 'p', children: [{ text: '' }] }
            ], {})
        },
        clearTextbox: () => {
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });
        },
        focusTextbox: () => {
            ReactEditor.focus(editor)
        }
    }

    useEffect(() => {
        setApi(api);
    }, [])

    return (
        <Slate editor={editor} value={[{ type: 'p', children: [{ text: ' ' }] }]} onChange={handleStateChange}>
            <div className='textAreaInputContainer'>
                <SlateToolbar showtoolbar={richTextMenu} />
                <Editable
                    className='textAreaInput'
                    renderElement={(props) => <Element {...props} />}
                    renderLeaf={(props) => <Leaf {...props} />}
                    onKeyDown={handleKeyDown}
                    spellCheck={false}
                />
                <div className='rightMenuIconDiv' onClick={() => setRightMenu(!rightMenu)}>
                    <i className={`fa-solid fa-angle-left${rightMenu ? ' open':''}`} ></i> 
                </div>                
            </div>
        </Slate>
    )
}

const SlateToolbar = ({ showtoolbar }) => {
    const editor = useSlate();
    const selection = useSlateSelection();
    const [picker, setPicker] = useState('');
    const [styling, setStyling] = useState({})
    const toolbarRef = useRef(null);

    useEffect(() => {
        const handleClickout = (e) => {
            if (toolbarRef.current && !toolbarRef.current.contains(e.target)) {
                setPicker('');
            }
        }
        if (picker && toolbarRef) document.addEventListener('click', handleClickout);
        return () => document.removeEventListener('click', handleClickout)
    }, [picker, toolbarRef])

    useEffect(() => {
        setStyling({
            fontFamily: getMarkValue(editor, '$style_fontFamily'),
            fontSize: getMarkValue(editor, '$style_fontSize'),
            color: getMarkValue(editor, '$style_color'),
            backgroundColor: getMarkValue(editor, '$style_backgroundColor')
        })
    }, [selection])

    return (
        <div className={`richTextMenu ${!showtoolbar ? 'closed' : 'open'}`} ref={toolbarRef} >
            <SelectDropdown
                optionList={fonts}
                setStyle={true}
                format='fontFamily'
                currentVal={styling.fontFamily}
                change={(val) => {
                    Editor.addMark(editor, '$style_fontFamily', val);
                    ReactEditor.focus(editor)
                }}
            />
            <SelectDropdown
                optionList={sizes}
                setStyle={false}
                format='fontSize'
                currentVal={styling.fontSize}
                change={(val) => {
                    Editor.addMark(editor, '$style_fontSize', val);
                    ReactEditor.focus(editor)
                }}
                width='60px'
            />
            <ToolButton type='mark' icon='bold' format='$style_fontWeight' value='bold' />
            <ToolButton type='mark' icon='italic' format='$style_fontStyle' value='italic' />
            <ToolButton type='mark' icon='underline' format='$style_textDecoration' value='underline' />
            <ToolButton type='mark' icon='strikethrough' format='$style_textDecoration' value='line-through' />
            <ToolButton type='block' icon='code' format='code' />
            <ColorPicker
                defaultColor={{ r: 0, g: 0, b: 0, a: 0.89 }}
                currentColor={styling.color}
                click={() => { setPicker(picker === 'fontColor' ? '' : 'fontColor') }}
                pickerOpen={picker === 'fontColor'}
                complete={(color) => {
                    setMark(editor, '$style_color', color)
                    ReactEditor.focus(editor)
                }}
            />
            <ColorPicker
                inverted
                defaultColor={{ r: 100, g: 100, b: 100, a: 1 }}
                currentColor={styling.backgroundColor}
                click={() => { setPicker(picker === 'backgroundColor' ? '' : 'backgroundColor') }}
                pickerOpen={picker === 'backgroundColor'}
                complete={(color) => {
                    setMark(editor, '$style_backgroundColor', color)
                    ReactEditor.focus(editor)
                }}
            />
            <ToolButton type='block' icon='list-ol' format='numbered-list' />
            <ToolButton type='block' icon='list' format='bulleted-list' />
            <ToolButton type='mark' icon='subscript' format='type' value='sub' />
            <ToolButton type='mark' icon='superscript' format='type' value='sup' />
        </div>
    )
}

const ToolButton = ({ type, icon, format, value }) => {
    const editor = useSlate();
    if (type === 'mark') {
        return <ToolbarButton
            icon={icon}
            mousedown={() => { setMark(editor, format, value); ReactEditor.focus(editor) }}
            active={getMarkValue(editor, format) === value} />
    } else if (type === 'block') {
        return <ToolbarButton
            icon={icon}
            mousedown={() => { toggleBlock(editor, format); ReactEditor.focus(editor); }}
            active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')} />
    }
}

const getMarkValue = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks && marks[format]
}

const setMark = (editor, format, value) => {
    const currentVal = getMarkValue(editor, format);
    if (value !== currentVal) {
        Editor.addMark(editor, format, value);
    } else if (value === false || value === currentVal) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
}

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )
    return !!match
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'p' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)
    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}


