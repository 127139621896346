import './ImageUpload.css'
import FormField from './FormField';
import Croppie from 'croppie';
import 'croppie/croppie.css'

import { useEffect, useRef, useState } from 'react';
import { useStateValue } from '../System/StateProvider';

export default function ImageUpload(props) {
    const [{mobile}, dispatch] = useStateValue();
    const [errMsg, setErrMsg] = useState(null);
    const [fileDataURL, setFileDataURL] = useState('');
    const [customImageURL, setCustomImageURL] = useState('');
    const imagePreview = useRef(null);
    const [croppie, setCroppie] = useState(null);

    const loadImage = async () => {
        const res = await fetch(customImageURL);
        const buff = await res.blob();
        loadPreview(buff);
    }

    const handleFile = (e) => {
        e.preventDefault();
        const file = (e?.dataTransfer?.items) ? e.dataTransfer.items[0].getAsFile():e.target.files[0];
        loadPreview(file);
        e.target.value = null;
    }

    const checkFile = (uploadedFile) => {
        setErrMsg(null);
        if (!uploadedFile?.type.startsWith('image/')) {
            setErrMsg('This file is not an image.');
            return false
        } 
        return true
    }    
    
    const loadPreview = (file) => {
        if (checkFile(file)) {
            const fr = new FileReader();
            fr.onload = () => {        
                setFileDataURL(fr.result);
            }
            fr.readAsDataURL(file)
        }
    }

    const getCroppedImage = () => {
        croppie.result(props.outputSettings).then((output) => {
            props.upload({
                image: output,
                type: props.outputSettings.type,
                format: props.outputSettings.format
            });
        })
    }

    useEffect(() => {
        if (fileDataURL && imagePreview.current) {
            const croppieInstance = new Croppie(imagePreview.current, {
                viewport: props.cropSettings
            });
            croppieInstance.bind({
                url: fileDataURL
            })
            setCroppie(croppieInstance);
        }
    }, [fileDataURL])
    
    if (fileDataURL) {
        return (
            <div className='uploadedImageDiv'>
                <div className='imagePreviewDiv' ref={imagePreview}></div>
                <div className='uploadedImageButtonDiv'>
                    <button onClick={getCroppedImage}>Upload</button>
                    <button onClick={() => {
                        setCustomImageURL('');
                        setFileDataURL('');  
                    }}>Cancel</button>
                </div>
            </div>
        )
    } else {
        return (
            <>
            <div className='imageUploadDiv'
                onDrop={handleFile}
                onDragOver={(e) => {e.preventDefault()}}
            >
                <h4>Drag Image Here</h4>
                <h5>Or</h5>
                <label className='fileInputLabel'>                
                    <input 
                        type="file" 
                        name="Browse" 
                        className='fileUploadInput' 
                        accept='image/*'
                        onInput={handleFile}
                    />
                    <h5>Browse</h5>
                </label>
                <div className='errorDiv' style={errMsg ? {}:{display: 'none'}}>
                    <h5>{errMsg}</h5>
                </div>
            </div>
            <div>
                <FormField 
                    val={customImageURL}
                    change={setCustomImageURL}
                    type={'text_with_button'}
                    placeholder={'Url'}
                    buttonText={'Load'}
                    buttonPosition={mobile ? 'bottom':'attached'}
                    buttonClick={loadImage}
                />
            </div>   
            </>
        )
    }

}

