//https://beta.reactjs.org/learn/scaling-up-with-reducer-and-context
import React, { createContext, useContext, useReducer } from 'react';
import { screenBreakpoint, sidebarWidth, handleWidth, sidebarOpen } from './globalConstants';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return(
		<StateContext.Provider value={[state, dispatch]}>
			{children}
		</StateContext.Provider>
	)
};

export const useStateValue = () => useContext(StateContext);

const initialState = {
	user: null,
	signedIn: false,
	loading: true,
	muted: false,
	alertVolume: window.localStorage.getItem('volume') ? parseInt(window.localStorage.getItem('volume')):100,
	mobile: window.innerWidth < screenBreakpoint,
	currentRoom: null,
	roomData: {},
	messages: {},
	hideSidebar: false,
	sidebarState: window.innerWidth < screenBreakpoint ? 'closed':'open',
	sidebarBasis: sidebarWidth+handleWidth+(window.innerWidth < screenBreakpoint ? 0:sidebarOpen),
	sidebarPage: '',
	currentVideoChatRoom: null
};

const reducer = (state, action) => {
	if (action.hasOwnProperty('sidebarState') || action.hasOwnProperty('sidebarBasis')) {
		const newState = {};
		if (action.sidebarState) {
			switch(action.sidebarState) {
				case 'open': 
					newState.state = 'open';
					newState.basis = action.sidebarBasis || state.sidebarBasis;
					break;
				case 'closed':
					newState.state = 'closed';
					newState.basis = sidebarWidth + handleWidth;
					break;
				default:
					newState.state = 'closed';
					newState.basis = sidebarWidth + handleWidth;
					break;
			}
			return {
				...state,
				hideSidebar: false,
				sidebarState: newState.state,
				sidebarBasis: newState.basis
			}	
		} else {
			let newBasis = action.sidebarBasis || state.sidebarBasis;
			if (newBasis <= (handleWidth + sidebarWidth + 15)) {
				newState.state = 'closed';
				newState.basis = handleWidth + sidebarWidth;
			} else {
				newState.state = 'open';
				newState.basis = newBasis
			}
			return {
				...state,
				hideSidebar: false,
				sidebarState: newState.state,
				sidebarBasis: newState.basis						
			}  
		}
	} else if (action.type) {
		switch(action.type) {
			default:
				break;	
		}
	} else {
		return {
			...state,
			...action
		};
	}
};