//import { useRef } from "react";
import React, {createRef} from "react";

const map = new Map();

const setRef = (key) => {
    if (!key) return console.warn(`useDynamicRefs: Cannot set ref without key `);
    const ref = React.createRef();
    map.set(key, ref);
    return ref;
}

const getRef = (key) => {
    if (!key) return console.warn(`useDynamicRefs: Cannot get ref without key`);
    return map.get(key);
}

export default function useDynamicRefs() {
    return [getRef, setRef];
}