
const screenBreakpoint = 600;
const apiPath = process.env.REACT_APP_ENV === 'dev ' ? 'http://192.168.1.202:421':'';
const handleWidth = 0;    
const sidebarWidth = 65;
const sidebarOpen = 200;
const mobileSidebarOpen = 120;
const defaultAvatar = 'https://react.semantic-ui.com/images/avatar/small/matthew.png';

const defaultPrefs = {
    "allow_private": true,
    "auto_close_sidebar": true,
    "auto_open_sidebar": true,
    "outgoing_sound": true,
    "public_email": true,
    "scroll_on_new": true,
    "auto_focus_textbox": true
}

const replaceObj = {
    '/shrug': '¯\\_(ツ)_/¯',
    '/doubleflip': '┻━┻ ︵ ¯\\_(°□°)_/¯ ︵ ┻━┻',
    '/tableflip': '(╯°□°）╯︵ ┻━┻',
    '/tableback': '┬─┬ ノ( ゜-゜ノ)',
    '/flipoff': '╭∩╮(︶︿︶)╭∩╮',
    '/algebraic': '| ( •◡•)| (❍ᴥ❍ʋ)',
    '/sunglasses': '( •_•)\n( •_•)>⌐■-■\n(⌐■_■)',
    '/wizard': '(∩ ` -´)⊃━━☆ﾟ.*･｡ﾟ',
    '/boobs': '( . Y . )',
    '/idk': '¯\\_(°_o)_/¯',
    '/success': '(•̀ᴗ•́)و',
    '/this': '☜(ﾟヮﾟ☜)',
    '/that': '(☞ﾟヮﾟ)☞',
    '/yuno': 'ლ(ಠ益ಠლ)',
    '/danceparty': '♪┏(・o･)┛♪┗ ( ･o･) ┓♪',
    '/pinch': '( • )( • )ԅ(≖⌣≖ԅ)',
    '/hearteyes': '( ♥‿♥)',
    '/cry': '(oཀ︹ཀ)'
};

const defaultAvatars = [
    "https://react.semantic-ui.com/images/avatar/small/matt.jpg",
    "https://react.semantic-ui.com/images/avatar/small/elliot.jpg",
    "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
    "https://react.semantic-ui.com/images/avatar/small/joe.jpg",
    "https://react.semantic-ui.com/images/avatar/small/stevie.jpg",
    "https://react.semantic-ui.com/images/avatar/small/steve.jpg",
    "https://react.semantic-ui.com/images/avatar/small/christian.jpg",
    "https://react.semantic-ui.com/images/avatar/small/molly.png",
    "https://react.semantic-ui.com/images/avatar/small/daniel.jpg",
    "https://react.semantic-ui.com/images/avatar/small/matthew.png",
    "https://react.semantic-ui.com/images/avatar/small/ade.jpg",
    "https://react.semantic-ui.com/images/avatar/small/helen.jpg"
];


export { 
    screenBreakpoint, 
    apiPath, 
    handleWidth, 
    sidebarWidth, 
    replaceObj, 
    defaultAvatar, 
    defaultAvatars,
    sidebarOpen,
    mobileSidebarOpen,
    defaultPrefs
}
