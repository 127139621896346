import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link, useMatches} from "react-router-dom";
import { useAuthManager } from "../System/API.js";
import "./CreateAccount.css";
import { useStateValue } from '../System/StateProvider.js';
import { Form, Message, Icon, Input, Popup } from 'semantic-ui-react';

export default function CreateAccount() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isValid, setValid] = useState(false);
    const [{user}] = useStateValue();
    const navigate = useNavigate();
    const authManager = useAuthManager();
    const match = useMatches()
    const path = match[match.length-1].pathname.split('signup')[1];

    const checkValid = useCallback(() => {
        const completeCheck = (!email || !password || (password !== confPassword)) ? false:true;
        const emailCheck = new RegExp('^([a-zA-Z0-9._:$!%-]+)@([a-zA-Z0-9.-]+)[.]([a-zA-Z]+)$').test(email);
        const pwCheck = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$').test(password);
        setValid(emailCheck && pwCheck && completeCheck);  
    }, [email, password, confPassword]) 

    useEffect(() => { 
        checkValid();
    }, [user, email, password, confPassword])

    useEffect(() => {
        if (user) {
            navigate(path || '/', {replace: true})
        }
    }, [user]); 

    function doCreateUser(e) {
        e.preventDefault();
        authManager.createUser(email, password)
            .catch((error) => {
                setErrorMsg(error.code);
            })
    } 
 
    return (
        <div className="loginPageContainer">
            <div className="loginContainer">
                <Message attached>
                    <Message.Header>
                        Welcome to 
                        <h1 className="titleString">Bien<span id={"bolder"}>Chats</span>&nbsp;</h1>
                    </Message.Header>    
                    <Message.Content>Fill out the form below to sign-up for a new account.</Message.Content>
                </Message>         
                <Form className='loginForm attached fluid segment' onSubmit={doCreateUser} error={Boolean(errorMsg)}>
                    <Form.Field>
                        <label>Email:</label> 
                        <Input value={email} onChange={(e) => {setEmail(e.target.value)}} type="text" placeholder='Email' />
                    </Form.Field>
                    <Form.Field>
                        <label>
                            Password:
                            <Popup 
                                trigger={<Icon name='help circle large' style={{marginLeft: "10px"}} />}
                                wide
                            >
                                <Popup.Header>Password Rules</Popup.Header>
                                <Message>
                                    <p>Your password must have at least 8 characters and contain at least one of:</p>
                                    <Message.List>
                                        <Message.Item>Uppercase Letter</Message.Item>
                                        <Message.Item>Lowecase Letter</Message.Item>
                                        <Message.Item>Number</Message.Item>
                                    </Message.List>
                                </Message>
                            </Popup>
                        </label>
                        <Input 
                            icon={
                                <Icon link name={'eye' } onClick={(e) => {
                                    const inp = e.target.previousElementSibling;
                                    inp.type = inp.type === 'password' ? 'text':'password';
                                    e.target.classList.toggle('slash');
                                }} />
                            }
                            className='inputIcon'
                            type={'password'}
                            value={password} 
                            placeholder='Password'
                            onChange={(e) => {setPassword(e.target.value)}}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Confirm Password:</label>
                        <Input 
                            icon={
                                <Icon link name={'eye' } onClick={(e) => {
                                    const inp = e.target.previousElementSibling;
                                    inp.type = inp.type === 'password' ? 'text':'password';
                                    e.target.classList.toggle('slash');
                                }} />
                            }
                            className='inputIcon'
                            type={'password'}
                            value={confPassword} 
                            placeholder='Confirm Password'
                            onChange={(e) => {setConfPassword(e.target.value)}}
                        />
                    </Form.Field>                
                    <Message
                        error
                        header={'Error:'}
                        content={errorMsg}
                    />
                    <Form.Button content='Sign Up' disabled={!isValid} primary/>
                </Form>
                <Message attached='bottom' warning>
                    <Icon name='exclamation circle' />
                    Already signed up?&nbsp;<Link to={'/login'+path}>Login here</Link>&nbsp;instead.
                </Message>
            </div>
        </div>
    )
}






