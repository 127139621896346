import './Profile.css';
import { useStateValue } from '../System/StateProvider';
import { useState, useReducer } from 'react';
import FormField from '../Components/FormField';
import { useNavigate } from 'react-router-dom';
import { useAuthManager } from '../System/API';

const usernameReducer = (currentUsername, newUsername) => {
    if (newUsername.length > 40) {
        return currentUsername
    } else {
        return newUsername
    }
}

export default function Profile(props) {
    const [{user, mobile}, dispatch] = useStateValue();
    const [username, setUsername] = useReducer(usernameReducer, user.username);
    const [email, setEmail] = useState(user.email);
    const navigate = useNavigate();
    const authManager = useAuthManager();

    const saveUsername = () => {
        if (username) {
            authManager.setUsername(username);
        }
    }   

    const openAvatarMenu = () => {
        navigate('/settings/avatar', { replace:false });
    }

    return (
        <>         
            <div className='userProfileFormContainer'>
                <div className='avatarInputContainer'>
                    <h4 className='userProfileFieldLabel'>Avatar:</h4>
                    <div className='avatarContainerDiv'>
                        <img className='avatarImage' src={user.avatar} width={200} height={200} />
                        <button onClick={openAvatarMenu} className='avatarChangeButton'>Change</button>
                    </div>
                </div>                
                <div className='formFieldGroup'>
                    <FormField 
                        label={'Username:'}
                        val={username}
                        change={setUsername}
                        type={'text_with_button'}
                        placeholder={'Username'}
                        buttonText={'Update'}
                        buttonClick={saveUsername}
                        buttonPosition={mobile ? 'bottom':'attached'}
                    />
                    <FormField 
                        label={'Email:'}
                        type={'text'}
                        val={email}
                        placeholder={'Email'}
                        disabled
                    />
                </div>
            </div>    
        </>
    )
}