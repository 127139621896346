import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import './ToolTip.css'

const ToolTip = forwardRef(({ children, content, type = 'hover', clickout, timeout, posH, posV, pivH, pivV, offsetH, offsetV }, ref) => {
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipStyle, setTooltipStyle] = useState({})
    const tooltipContainer = useRef(null);
    const tooltip = useRef(null);

    useImperativeHandle(ref, () => ({
        closeTooltip: () => {closeTooltip()},
        openTooltip: () => {openTooltip()},
        toggleTooltip: () => {toggleTooltip()}
    }))

    const toggleTooltip = (e) => {
        e && e.stopPropagation();
        if (tooltipVisible) {
            closeTooltip(e);
        } else {
            openTooltip(e);
        }
    }

    const openTooltip = (e) => {
        e && e.stopPropagation();
        if (type === 'hover' || timeout) {
            if (timeoutRef) {
                clearTimeout(timeoutRef)
            }
            setTimeoutRef(setTimeout(() => {
                setTooltipVisible(true);
            }, timeout || 500))
        } else {
            setTooltipVisible(true);
        }
    }

    const closeTooltip = (e) => {
        e && e.stopPropagation();
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        setTooltipVisible(false);
    }

    useEffect(() => {
        const clickoutListener = (e) => {
            if (!tooltip.current.contains(e.target)) {
                closeTooltip(e)
            }
        }
        if (tooltipVisible && tooltip.current && clickout) {
            document.body.addEventListener('pointerdown', clickoutListener);
        }

        if (tooltipContainer.current && tooltip.current) {
            const containerPos = tooltipContainer.current.getBoundingClientRect();
            const tooltipPos = tooltip.current.getBoundingClientRect();

            const positions = {
                offsetTop: tooltipPos.height,
                offsetLeft: 0,
                posTop: containerPos.top,
                posLeft: containerPos.left
            }
            switch (pivV) {
                case 'top':
                    positions.offsetTop = 0
                    break
                case 'center':
                    positions.offsetTop = tooltipPos.height / 2
                    break
                default:
                    break
            }
            switch (pivH) {
                case 'right':
                    positions.offsetLeft = tooltipPos.width
                    break
                case 'center':
                    positions.offsetLeft = tooltipPos.width / 2
                    break
                default:
                    break
            }
            switch (posV) {
                case 'bottom':
                    positions.posTop = containerPos.top + containerPos.height
                    break
                case 'center':
                    positions.posTop = containerPos.top + containerPos.height / 2
                    break
                default:
                    break
            }
            switch (posH) {
                case 'right':
                    positions.posLeft = containerPos.left + containerPos.width
                    break
                case 'center':
                    positions.posLeft = containerPos.left + containerPos.width / 2
                    break
                default:
                    break
            }

            setTooltipStyle({
                top: positions.posTop - positions.offsetTop + (offsetV ? parseInt(offsetV) : 0),
                left: positions.posLeft - positions.offsetLeft + (offsetH ? parseInt(offsetH) : 0),
                position: 'absolute'
            })
        }

        return () => {
            document.body.removeEventListener('pointerdown', clickoutListener);
        }
    }, [tooltipContainer, tooltip, tooltipVisible])

    return (
        <>
            <div
                className='tooltip'
                onPointerEnter={type === 'hover' ? openTooltip : undefined}
                onPointerLeave={type === 'hover' ? closeTooltip : undefined}
                onPointerDown={type === 'click' ? openTooltip : type === 'toggle' ? toggleTooltip : undefined}
                onPointerUp={type === 'click' ? closeTooltip : undefined}
                onPointerCancel={type === 'click' ? closeTooltip : undefined}
                ref={tooltipContainer}
            >
                {children}
            </div>
            {(tooltipVisible && typeof content === 'string') &&
                createPortal(
                    <span className='tooltipContent' style={tooltipStyle} ref={tooltip} >{content}</span>,
                    document.querySelector('div#root')
                )
            }
            {(tooltipVisible && typeof content === 'object') &&
                createPortal(
                    <div className='tooltipContent' style={tooltipStyle} ref={tooltip} >{content}</div>,
                    document.querySelector('div#root')
                )
            }
        </>
    )
})

export default ToolTip