import { SketchPicker } from 'react-color'
import { useEffect, useState } from 'react';


export const ToolbarButton = ({ icon, active, mousedown }) => {
    return (
        <div
            active={active ? 'true':null}
            onMouseDown={(e) => {
                e.preventDefault();
                mousedown();
            }}
            className='iconContainer'
        >
            <i className={`fa-solid fa-${icon}`}></i>
        </div>
    )
}

export function ColorPicker({ defaultColor, currentColor, click, pickerOpen, complete, inverted }) {
    const [color, setColor] = useState(defaultColor);

    const colorToText = (color) => {
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    }
    
    const textToColor = (text) => {
        const colorArray = text.split('rgba(')[1].split(')')[0].split(',').map(n => parseFloat(n));
        return {
            r: colorArray[0],
            g: colorArray[1],
            b: colorArray[2],
            a: colorArray[3]
        }
    }    

    useEffect(() => {
        setColor(currentColor ? textToColor(currentColor):defaultColor)
    }, [currentColor])

    return (
        <div className='colorPickerDiv' >
            <div onMouseDown={click} className='iconContainer'>
                <i
                    className={`fa-solid fa-font ${inverted ? 'inverted' : ''}`}
                    style={{
                        borderColor: inverted ? null:colorToText(color),
                        backgroundColor: inverted ? colorToText(color): null
                    }}
                />
            </div>
            {pickerOpen && <SketchPicker
                color={color}
                onChange={(colorObj) => setColor(colorObj.rgb)}
                onChangeComplete={(colorObj) => {
                    complete(colorToText(colorObj.rgb))
                }}
            />}
        </div>
    )
}

export function SelectDropdown({ optionList, currentVal, change, width, setStyle, format }) {
    const defaultVal = optionList.find(r => r.default === true).value;
    const [value, setValue] = useState(defaultVal);

    const handleChange = (e) => {
        setValue(e.target.value);
        change(e.target.value);
    }

    useEffect(() => {
        setValue(currentVal || defaultVal);
    }, [currentVal])

    return (
        <div className='dropdownContainer' style={{ width: width || null }}>
            <select
                value={value}
                onChange={handleChange}
                style={{ fontFamily: setStyle ? value : null }}
            >
                {
                    optionList.map(prop => {
                        return (
                            <option
                                key={prop.value}
                                value={prop.value}
                                style={{ [format]: setStyle ? prop.value : null }}
                            >
                                {prop.displayVal || prop.value}
                            </option>
                        )
                    })
                }
            </select>
        </div>
    )
}