import { useEffect, useRef, useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import { useAuthManager } from '../System/API';
import './GifSearch.css';


export default function GifSearch(props) {
    const authManager = useAuthManager();
    const [gifList, setGifList] = useState(null);
    const searchContainer = useRef(null);
    const gifInputRef = useRef(null);
    const [columns, setColumns] = useState(4);
    const [searchString, setSearchString] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);

    const gifSearchInput = (val) => {
        setSearchString(val);
        if (searchTimeout) {
            clearInterval(searchTimeout);
        }
        setSearchTimeout(setTimeout(() => searchGifs(val), 500));
    }

    useEffect(() => {
        if (gifInputRef.current) {
            gifInputRef.current.focus()
        }
    }, [gifInputRef])

    const searchGifs = (string) => {
        authManager.searchGifs({
            query: string ?? searchString
        }).then(res => {
            setGifList(res);
        })        
    }

    const loadMore = () => {
        authManager.searchGifs({
            query: searchString,
            pos: gifList.next 
        }).then(res => {
            console.log(res);
            let newGifList = {};
            newGifList.results = [
                ...gifList.results,
                ...res.results
            ]
            newGifList.next = res.next
            setGifList(newGifList);
        }) 
    }

    useEffect(() => {
        searchGifs();
        setTimeout(() => {
            searchContainer.current.classList.add('open');
        }, 50);        
    }, [])

    useEffect(() => {
        props.setRef(searchContainer);
        const observer = new ResizeObserver(entries => {
            const width = entries[0].contentRect.width;
            setColumns(Math.floor(width/(155)));
        })
        observer.observe(searchContainer.current)
        return () => searchContainer.current && observer.unobserve(searchContainer.current)        
    }, [searchContainer, gifList])

    return (
        <div className='tenorGifSearchContainer' ref={searchContainer}>
            <div className='gifSearchMenu'>
                <Icon 
                    name='angle double down'
                    onClick={props.closeMenu}
                    circular
                />
                <div className='searchInputContainer'>
                    <input 
                        className='gifSearchInput'
                        type="text"
                        ref={gifInputRef}
                        value={searchString}
                        onChange={(e) => gifSearchInput(e.target.value)}
                        placeholder={'Search Tenor...'}
                        spellCheck={false}
                    />
                    <Icon 
                        name='close' 
                        circular
                        onClick={() => {
                            if (searchString) {
                                gifSearchInput('');
                            }
                        }}
                        inverted
                    />                    
                </div>
            </div>
            <div className='gifSetContainer'>
                <div className='gifSet'>
                    { gifList && 
                    Array(columns).fill('col').map((col, indx) => {
                        const gifSet = gifList.results.filter((gif, index) => {
                            return index%columns === indx
                        });
                        return (
                        <div className='gifCols' key={indx}>
                            {
                            gifSet.map((gif) => {
                                return ( 
                                    <div 
                                        key={gif.id} 
                                        className='gifContainer'
                                        onClick={() => {props.selectGif(gif)}}
                                    >
                                        <img src={gif.media_formats.gif.url} width={150} />
                                    </div>
                                )
                            })                                     
                            }
                        </div>
                        )
                    })                                    
                    } 
                </div>
                {
                    gifList && gifList.next &&
                    <button 
                        className='loadMoreButton'
                        onClick={loadMore}
                    >Load More</button>
                }                  
            </div>          
        </div>
    )
}