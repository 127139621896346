import { useStateValue } from "../System/StateProvider";
import Header from "./Header";
import AudioElement from "../Audio/AudioManager";
import { screenBreakpoint } from "../System/globalConstants";
import { useEffect } from "react";

export default function MobileWrapper(props) {
    const [{ mobile }, dispatch] = useStateValue();

    useEffect(() => {
        function setMobile() {
            if ((mobile && window.innerWidth >= screenBreakpoint) || (!mobile && window.innerWidth < screenBreakpoint)) {
                dispatch({ mobile: window.innerWidth < screenBreakpoint })
            }
        }
        window.addEventListener('resize', setMobile);
        return () => {
            window.removeEventListener('resize', setMobile);
        }
    }, [mobile])

    if (mobile) {
        return (
            <div className='mainPageContainer'>
                <Header />
                {props.children}
                <AudioElement />
            </div>
        )
    } else {
        return (
            <div className='outerContainer'>
                <div className='mainPageContainer'>
                    <Header />
                    {props.children}
                </div>
                <AudioElement />
            </div>
        )
    }
}
