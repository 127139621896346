
import React, { createContext, useContext } from 'react';
import { io } from 'socket.io-client';
import { apiPath } from './globalConstants';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    return (
        <SocketContext.Provider value={socketHandler}>
            {children}
        </SocketContext.Provider>
    )
}

export const useSocket = () => {
    return useContext(SocketContext);
}

let socketHandler = {
    socket: null,
    initSocket: (user) => {
        if (!socketHandler.socket) {
            socketHandler.socket = io(apiPath, {
                path: '/BienChats/',
                query: {
                    uid: user.uid,
                    public_uid: user.public_uid,
                    rooms: JSON.stringify(user.subscribed)
                },
                withCredentials: true
            })
        } else if (socketHandler.socket.disconnected) {
            socketHandler.socket.connect();
        }
    },
    disconnect: () => {
        if (socketHandler.socket && socketHandler.socket.connected) {
            socketHandler.socket.disconnect();
        }
    },
    connect: () => {
        if (socketHandler.socket && socketHandler.socket.disconnected) {
            socketHandler.socket.connect();
        }
    },
    sendMessage: (msgBody) => {
        if (socketHandler.socket && socketHandler.socket.connected) {
            socketHandler.socket.emit('send_message', msgBody);
        }
    }
}
