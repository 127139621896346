import { initializeApp } from "firebase/app";
import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	onAuthStateChanged
} from "firebase/auth";
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { useStateValue } from "./StateProvider";
//import { useAuthState } from 'react-firebase-hooks/auth';
import { defaultPrefs } from "./globalConstants";

const firebaseConfig = {
	apiKey: "AIzaSyDA9MADToeQNtmjKDvJYHsBfmXMs7df7Hc",
	authDomain: "bienchats.firebaseapp.com",
	projectId: "bienchats",
	storageBucket: "bienchats.appspot.com",
	messagingSenderId: "685100461447",
	appId: "1:685100461447:web:677cf1fe0c01583a2ef722",
	measurementId: "G-19C8ZNTG66"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export function useStorageManager() {
	const [{user}, dispatch] = useStateValue();
	const {apiPost, apiGet} = useAPI();

	const storageManager = {
		uploadAvatar: (imgObj) => {
			const storageRef = ref(storage, `avatars/avatar${user.public_uid}.${imgObj.format}`);
			return uploadBytes(storageRef, imgObj.image, {
				contentType: 'image/jpeg',
				cacheControl: "private,max-age=86400"
			}).then(snapshot => {
				const newUrl = `https://storage.googleapis.com/bienchats.appspot.com/${snapshot.metadata.fullPath}?${Date.now()}`
				return apiPost(`/api/avatar`, {
					url: newUrl,
					public_uid: user.public_uid
				}).then(res => {
					user.avatar = newUrl;
					dispatch({ user: user });
					return newUrl
				})
			})
		}
	}
	return storageManager
} 


const useAPI = () => {
	const [{user}, dispatch] = useStateValue();

	const apiGet = (path) => {
		const getRequestOptions = {
			method: 'GET',
			credentials: 'include',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Headers': 'application/json'
			}
		}
		if (process.env.REACT_APP_ENV === 'dev ') {
			console.log(`Getting from: ${path}`);
		}
		return fetch(path, getRequestOptions)
		.then(res => {
			if (res.ok) return res.json();
			return Promise.reject(res.statusText);
		})
	}

	const apiPost = (path, body) => {
		const appendedBody = {
			...body,
			_csrf: getCookie('_csrf'),
			userData: {
				uid: user?.uid,
				public_uid: user?.public_uid	
			}
		}
		const postRequestOptions = {
			method: 'POST',
			credentials: 'include',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Headers': 'application/json',
				"Content-Type": "application/json"
			},
			body: JSON.stringify(appendedBody)
		}
		if (process.env.REACT_APP_ENV === 'dev ') {
			console.log(`Posting to: ${path}`);
		}
		return fetch(path, postRequestOptions)
		.then(res => {
			if (res.ok) return res.json();
			return Promise.reject(res.statusText);
		})
	}
	
	return {apiPost, apiGet}
}


const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

export function useAuthManager() {
	const [{roomData, user, messages}, dispatch] = useStateValue();
	const {apiPost, apiGet} = useAPI();

	const authManager = {
		createUser: (email, password) => {
			return createUserWithEmailAndPassword(auth, email, password);
		},
		signIn: (email, password) => {
			return signInWithEmailAndPassword(auth, email, password);
		},
		signOut: () => {
			return signOut(auth);
		},
		setUsername: (username) => {
			apiPost(`/api/user`, {
				public_uid: user.public_uid,
				username: username
			}).then(res => {
				user.username = username;
				dispatch({ user:user });
			})
		},
		updateUserPrefs: (prefs) => {
			apiPost(`/api/user_prefs`, {
				prefs: prefs,
				public_uid: user.public_uid
			}).then(res => {
				user.userPrefs = prefs;
				dispatch({ user:user })
			})
		},
		setAvatar: (dat) => {
			apiPost(`/api/avatar`, {
				url: dat.url,
				public_uid: user.public_uid
			})
			.then(res => {
				user.avatar = dat.url;
				dispatch({ user: user });
			})
		},
		uploadAvatar: (imgObj) => {
			apiPost(`/api/avatar/upload`, {
				...imgObj,
				user: user.public_uid,
				timestamp: Date.now()
			}).then(res => {
				if (process.env.REACT_APP_ENV === 'dev ') {
					console.log(res);
				}
			})
		},
		createRoom: (roomInfo) => {
			apiPost(`/api/createRoom`, roomInfo)
			.then(res => {
				roomData[res.room_id] = res;
				user.subscribed || (user.subscribed = {});
				user.subscribed[res.room_id] = true;
				dispatch({
					roomData: roomData,
					currentRoom: res.room_id,
					user: user
				})
			})
		},
		deleteRoom: (roomID) => {
			apiPost(`/api/deleteRoom`, {
				roomID: roomID,
				public_uid: user.public_uid,
				uid: user.uid
			}).then(res => {
				console.log(res);
			})
		},
		getRoomInfo: (roomObj) => {
			return apiPost(`/api/getRoom`, {
				...roomObj,
				public_uid: user.public_uid
			}).then(res => {
				if (res.message) {
					return res
				} else {
					roomData[roomObj.roomId] = res.roomData;
					messages[roomObj.roomId] = res.messages;
					dispatch({ 
						roomData: roomData,
						messages: messages,
						currentRoom: roomObj.roomId 
					});
					return true
				}
			})
		},
		getPublicRooms: () => {
			return apiGet(`/api/getPublicRooms`).then(res => {
				return res
			})
		},
		searchGifs: (searchObj) => {
			return apiPost(`/api/gifs`, searchObj).then(res => {
				return res
			})
		},
		init: () => {
			const host = `/api/login`;
			onAuthStateChanged(auth, (userData) => {
				if (!userData) {
					dispatch({
						user: null, 
						signedIn: false,
						currentRoom: null,
						roomData: {},
						messages: {},
						loading: false					
					})
					return;
				}
				dispatch({signedIn: true});
				userData.getIdToken().then(idToken => {
					apiGet(`/api/request_session`).then(res => {
						return apiPost(host, {
							idToken: idToken,
							uid: userData.uid
						})
					}).then(res => {
						if (res.message) return;
						res.userData.userPrefs = {
							...defaultPrefs,
							...res.userData.userPrefs
						}
						dispatch({
							user: res.userData, 
							signedIn: true,
							roomData: res.userRooms || {},
							messages: res.messages || {},
							loading: false
						})
					});
				})
			})
		}		
	}
	return authManager
}

