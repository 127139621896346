import './SidebarRouter.css';
import Rooms from "../SidebarPages/Rooms";
import { useStateValue } from '../System/StateProvider';
import { Icon } from "semantic-ui-react";
import CreateRoom from "../SidebarPages/CreateRoom";

export default function SidebarRouter() {
    const [{roomData, sidebarPage}, dispatch] = useStateValue();

    const setSidebarPage = (state) => {
        dispatch({
            sidebarPage: state
        })
    }

    if (!sidebarPage && !roomData) {
        return  (
            <div className="sidebarMenu">
                <div className='sidebarMenuContent' key={'norooms'}>
                    <NoRooms />
                </div>
                <SidebarMenuButtons setState={setSidebarPage} />
            </div>
        )
    } else if (sidebarPage === 'create') {
        return  (
            <div className="sidebarMenu">
                <div className='sidebarMenuContent' key={'create'}>
                    <div className='backDiv'>
                        <Icon 
                            onPointerDownCapture={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSidebarPage(''); 
                            }} 
                            className='backButtonIcon' 
                            name="arrow left" 
                            size='large' 
                            circular
                        />
                    </div>
                    <div className='sidebarPaddingDiv'>                    
                        <CreateRoom setState={setSidebarPage} />                        
                    </div>       
                </div>
            </div>
        )
    } else if (sidebarPage === 'join') {
        return  (
            <div className="sidebarMenu">
                <div className='sidebarMenuContent' key={'join'}>
                    <div className='backDiv'>
                        <Icon 
                            onPointerDownCapture={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSidebarPage(''); 
                            }} 
                            className='backButtonIcon' 
                            name="arrow left" 
                            size='large' 
                            circular
                        />
                    </div>             
                    <></>
                </div>
            </div> 
        )           
    } else {
        return  (
            <div className="sidebarMenu">
                <div className='sidebarMenuContent' key={'rooms'}>
                    <Rooms />
                </div>            
                <SidebarMenuButtons  setState={setSidebarPage} />
            </div>
        )
    }
            
}

function SidebarMenuButtons(props) {
    return (
        <div className='sidebarMenuButtonContainer'>
            <div onClick={() => {props.setState('create')}} className='sidebarMenuButton'>
                <h4>Create</h4>
            </div>
            {/* <div onClick={() => {props.setState('join')}} className='sidebarMenuButton'>
                <h4>Join</h4>
            </div> */}
        </div>  
    )
}

function NoRooms(props) {
    return (
        <div className="noRoomsSidebar">
            <h3 className='noRoomsText'>Looks like you aren't a member of any rooms yet. Lets fix that! You can join an existing room, or create one of your own.</h3>                      
        </div>        
    )
}