import './Avatar.css';
import { useAuthManager, useStorageManager } from '../System/API';
import { useEffect, useRef, useState } from 'react';
import ImageUpload from '../Components/ImageUpload';
import { defaultAvatars } from '../System/globalConstants';
import { useNavigate } from 'react-router-dom';

export default function Avatar() {
    const authManager = useAuthManager();
    const storageManager = useStorageManager();
    const navigate = useNavigate();
    const [columns, setColumns] = useState(6);
    const avatarSelect = useRef(null);

    const uploadNewAvatar = (imgData) => {
        storageManager.uploadAvatar(imgData).then(res => {
            navigate(`/settings/profile`, { replace: false });
        })
    }

    useEffect(() => {
        if (!avatarSelect || !avatarSelect.current) return;

        function setMobile() {
            if (!avatarSelect || !avatarSelect.current) return;
            let width = avatarSelect.current.parentElement.clientWidth;        
            if (width < 240) { setColumns(3) } 
            else if (width < 400) { setColumns(4) } 
            else { setColumns(6) }
        }

        let resize = new ResizeObserver(setMobile).observe(avatarSelect.current.parentElement);
        return () => {
            if (resize) {
                resize.disconnect();
            }
        }
    }, [columns])

    return (
        <div className='avatarSelectMenu'>
            <h4 className='avatarDivider'>Pick an avatar:</h4>
            <div 
                className='defaultAvatarSelect' 
                ref={avatarSelect} 
                style={{
                    gridTemplateColumns: '1fr '.repeat(columns)
                }}
            >
            {   
                defaultAvatars.map(r => {
                    return  (
                        <div className='avatarPreviewDiv' key={r}>
                            <img 
                                src={r} 
                                className="avatarPreview" 
                                value={r} 
                                width={50}
                                height={50}
                                onClick={(e) => {
                                    authManager.setAvatar({ url: r });
                                    navigate(`/settings/profile`, { replace: false });
                                }}
                            />
                        </div>
                    )
                })
            }
            </div>
            <h4 className='avatarDivider'>Provide your own:</h4> 
            <ImageUpload 
                upload={uploadNewAvatar}
                cropSettings={{
                    width: 200,
                    height: 200,
                    type: 'square'
                }}
                outputSettings={{
                    type: 'blob',
                    size: 'viewport',
                    format: 'jpeg',
                    quality: 1
                }}
            />

        </div>        
    )    
}

