import './Chat.css';
import { Form } from 'semantic-ui-react';
import { useStateValue } from '../System/StateProvider';
import { useEffect, useRef, useState } from 'react';
import Message from '../Components/Message';
import { useSocket } from '../System/Socket';
import GifSearch from '../Components/GifSearch';
import SlateEditor from '../Slate/SlateEditor';
import ToolTip from '../Components/ToolTip';
import VideoChat from '../System/VideoChat';

export default function Chat() {
    const [{ messages, currentRoom, roomData, user, currentVideoChatRoom }, dispatch] = useStateValue();
    const messageContainer = useRef(null);
    const mostCurrentTimestamp = (roomData && currentRoom && roomData[currentRoom]) && roomData[currentRoom].timestamp;
    const [scrollHeight, setScrollHeight] = useState(0)
    const [videoChat, setVideoChat] = useState(false);
    const videoChatAPI = useRef(null);
    const screenshareButton = useRef(null);
    const [sharingScreen, setSharingScreen] = useState(false);
    const vidButton = useRef(null);
    const muteButton = useRef(null);

    useEffect(() => {
        if (user?.userPrefs?.scroll_on_new ?? true) {
            scrollToBottom(true);
        }
    }, [messageContainer.current, mostCurrentTimestamp])

    useEffect(() => {
        setTimeout(() => {
            if (messageContainer.current.scrollHeight !== scrollHeight) {
                scrollToBottom();
            }
        }, 10)
    }, [scrollHeight])

    const scrollToBottom = (smooth) => {
        if (messageContainer.current) {
            setScrollHeight(messageContainer.current.scrollHeight);
            messageContainer.current.scrollTo({
                left: 0, 
                top: messageContainer.current.scrollHeight,
                behavior: smooth ? 'smooth':'instant'
            });            
        }
    }

    useEffect(() => {
        messageContainer.current.classList.add('animate')
        scrollToBottom();
    }, [currentRoom])

    useEffect(() => {
        if (videoChat && !currentVideoChatRoom) {
            closeVideoChat();
        } 
    }, [currentVideoChatRoom])

    const closeVideoChat = () => {
        setVideoChat(false);
        videoChatAPI.current.removeLocalStream();
    }

    const toggleMute = () => {
        const muted = videoChatAPI.current.toggleMute();
        if (muted) {
            muteButton.current.classList.remove('buttonOn')
            muteButton.current.children[0].classList.remove('fa-microphone-slash')
            muteButton.current.children[0].classList.add('fa-microphone')
        } else {
            muteButton.current.classList.add('buttonOn')
            muteButton.current.children[0].classList.add('fa-microphone-slash')
            muteButton.current.children[0].classList.remove('fa-microphone')
        }        
    }

    const toggleVid = () => {
        const vidHidden = videoChatAPI.current.toggleVid();
        if (vidHidden) {
            vidButton.current.classList.remove('buttonOn')
            vidButton.current.children[0].classList.remove('fa-video-slash')
            vidButton.current.children[0].classList.add('fa-video')
        } else {
            vidButton.current.classList.add('buttonOn')
            vidButton.current.children[0].classList.add('fa-video-slash')
            vidButton.current.children[0].classList.remove('fa-video')
        }        
    }

    const setScreen = () => {
        videoChatAPI.current.setScreen(sharingScreen).then(screensharing => {
            setSharingScreen(screensharing);
            if (screensharing) {
                screenshareButton.current.classList.add('buttonOn')
                screenshareButton.current.children[0].classList.add('fa-rectangle-xmark')
                screenshareButton.current.children[0].classList.remove('fa-display')
            } else {
                screenshareButton.current.classList.remove('buttonOn')
                screenshareButton.current.children[0].classList.remove('fa-rectangle-xmark')
                screenshareButton.current.children[0].classList.add('fa-display')
            }   
        })
    }

    return (
        <div className='pageContainer'>
            { videoChat &&
                <VideoChat callType={videoChat} returnAPI={(api) => {videoChatAPI.current = api}}/>
            }
            <div className='chatUpperToolbar'>
                { videoChat ? 
                    <div className='openVideoControls'>
                        <div className='controlButtonIcon' ref={muteButton}>
                            <i className="fa-solid fa-microphone" onClick={toggleMute}></i>
                        </div>
                        {   videoChat === 'video' &&
                            <>
                            <div className='controlButtonIcon' ref={vidButton}>
                                <i className="fa-solid fa-video" onClick={toggleVid}></i>
                            </div>
                            <div className='controlButtonIcon' ref={screenshareButton}>
                                <i className="fa-solid fa-display" onClick={setScreen}></i>
                            </div>
                            </>
                        }
                        <div className='controlButtonIcon endCallButton'>
                            <i className="fa-solid fa-phone-slash" onClick={closeVideoChat}></i>
                        </div>
                    </div>:
                    <div className='closedVideoControls'>
                        <div className='controlButtonIcon audioCall' onClick={() => {setVideoChat('audio')}} >
                            <i className="fa-solid fa-phone" />
                        </div>                        
                        <div className='controlButtonIcon videoCall' onClick={() => {setVideoChat('video')}} >
                            <i className="fa-solid fa-video" />
                            {(roomData[currentRoom]?.video_chat_users || 0) > 0 &&
                                <span>{roomData[currentRoom].video_chat_users}</span>
                            }
                        </div>
                    </div> 
                }
            </div>
            <div 
                id='chat-messages' 
                className='chatMessages' 
                ref={messageContainer} 
                onAnimationEnd={() => {messageContainer.current.classList.remove('animate')}}
            >
                {messages && currentRoom && messages[currentRoom] &&
                    Object.keys(messages[currentRoom]).map((r, index, arr) => {
                        let msg = {...messages[currentRoom][r]};
                        let prevMsg = {...messages[currentRoom][arr[index-1]]}
                        const combine = msg.username===prevMsg.username && (msg.timestamp-prevMsg.timestamp < 60000);
                        return (
                            <Message
                                key={r}
                                timestamp={msg.timestamp}
                                from={msg.username}
                                msg={msg.content}
                                type={msg.type}
                                avatar={msg.user_avatar}
                                loaded={scrollToBottom}
                                combine={combine}
                            />
                        )
                    })
                }
            </div>
            <ResponseArea />
        </div>
    )
}

const getMessageText = (messageObj) => {
    const parseTree = (jsonObj) => {
        let resString = '';
        for (let node of jsonObj) {
            if (node.text) {
                resString += decodeURIComponent(node.text);
            } else if (node.type === 'image') {
                resString += ' [Image] ';
            } else if (node.children && node.children.length) {
                resString += getMessageText(node.children)
            }
        }
        return resString
    }
    let theMessage = parseTree(messageObj);
    return theMessage
}

function ResponseArea() {
    const [{ user, currentRoom }, dispatch] = useStateValue();
    const socketManager = useSocket();
    const [message, setMessage] = useState([]);
    const [gifSearchOpen, setGifSearchOpen] = useState(false);
    const [gifSearchRef, setGifSearchRef] = useState(null);
    const [richTextMenu, setRichTextMenu] = useState(false);
    const [apiRef, setApiRef] = useState(null);
    const [rightMenuOpen, setRightMenuOpen] = useState(false);  

    const sendMessage = () => {
        let preview = getMessageText(message);
        if (preview.trim()!=='' && currentRoom) {
            const msgBody = {
                username: user.username,
                user_id: user.public_uid,
                user_avatar: user.avatar,
                room: currentRoom,
                type: 'rich_text',
                content: JSON.stringify(message),
                timestamp: Date.now(),
                preview: preview.length > 30 ? preview.substring(0, 30)+'...':preview
            }
            socketManager.sendMessage(msgBody);
            setMessage([]);
            apiRef.clearTextbox();
        }
    }

    const toggleGifSearch = () => {
        if (gifSearchOpen) {
            gifSearchRef.current.classList.remove('open');
            setTimeout(() => {
                setGifSearchOpen(false);
                apiRef.focusTextbox();
            }, 1000);
        } else {
            setGifSearchOpen(true);
        }
    }

    const selectGif = (gif) => {
        toggleGifSearch();
        apiRef.insertGif(gif.media_formats.gif.url);
    }
 
    return (
        <div className='responseDiv'>
            {gifSearchOpen &&
                <GifSearch
                    closeMenu={toggleGifSearch}
                    selectGif={selectGif}
                    setRef={(ref) => setGifSearchRef(ref)}
                />
            }
            <Form reply>
                <div className='textAreaContainer'>
                    <SlateEditor
                        richTextMenu={richTextMenu}
                        changeVal={(val) => setMessage(val)}
                        sendMsg={sendMessage}
                        setApi={(apiRef) => {setApiRef(apiRef)}}
                        setRightMenu={(open) => {setRightMenuOpen(open)}}
                        rightMenu={rightMenuOpen}
                    />
                    <div className='rightMenu'>
                        <div className={`rightMenuContentDiv${rightMenuOpen ? ' open':''}`}>
                            <ToolTip content='Tenor Gif Search' posH='center' pivH='center'>
                                <div className='rightMenuContentIconDiv' >
                                    <i className='fa-solid fa-images' onClick={toggleGifSearch} />
                                </div>
                            </ToolTip>
                            <ToolTip content='Rich Text Menu' posH='center' pivH='center'>
                                <div className='rightMenuContentIconDiv' >
                                    <i className='fa-solid fa-font' onClick={() => setRichTextMenu(!richTextMenu)} />
                                </div>
                            </ToolTip>
                        </div>
                        <div className='sendIconDiv' >
                            <i className={`fa-solid fa-paper-plane`} onClick={sendMessage}></i> 
                        </div>                         
                    </div>                    
                </div>
            </Form>
        </div>
    )
}


// function htmlToJson(div,obj){
//     if(!obj){obj=[]}
//     var tag = {}
//     tag['tagName']=div.tagName
//     tag['children'] = []
//     for(var i = 0; i< div.children.length;i++){
//         tag['children'].push(htmlToJson(div.children[i]))
//     }
//     for(var i = 0; i< div.attributes.length;i++){
//         var attr= div.attributes[i]
//         tag['@'+attr.name] = attr.value
//     }
//     return tag    
// }


//import { animateScroll } from "react-scroll";

// animateScroll.scrollToBottom({
//     containerId: 'chat-messages'
// })