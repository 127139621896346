import { useEffect, useState } from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import MobileWrapper from "./Components/MobileWrapper";
import Sidebar from "./Components/Sidebar";
import { useStateValue } from "./System/StateProvider";

export default function Root() {
    const navigate = useNavigate();
    const [{user, signedIn}, dispatch] = useStateValue();
    const match = useMatches()
    const [initPath, setInitPath] = useState(match[match.length-1].pathname)

    useEffect(() => {
        if (!signedIn) {
            navigate('/login'+initPath, {replace: false});
        }
    }, [user, signedIn])

    return  (
        <MobileWrapper>
            <Sidebar>
                <Outlet />
            </Sidebar>
        </MobileWrapper>
    )
}