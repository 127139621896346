import { useStateValue } from '../System/StateProvider';
import { useEffect, useRef, useState } from 'react';
import './Rooms.css';
import ToolTip from '../Components/ToolTip';
import { useAuthManager } from '../System/API';
import { useNavigate } from 'react-router-dom';

const randomColors = () => {
    const rand = () => Math.ceil(Math.random()*255);
    const color1 = [rand(), rand(), rand()];
    const color2 = [Math.floor(color1[0]/2), Math.floor(color1[1]/2), Math.floor(color1[2]/2)];
    return [`rgb(${color1})`, `rgb(${color2})`]
}

export default function Rooms() {
    const [{roomData, user}, dispatch] = useStateValue();
    return ( 
        <>
        {
            Object.keys(roomData).sort((a, b) => {
                return roomData[b].timestamp - roomData[a].timestamp;
            }).map(room => {
                return  (
                    <Room key={room} room={room} />
                )         
            })
        }               
        </>
    )
}

function Room({room}) {
    const [{roomData, currentRoom, sidebarState, sidebarBasis, user, currentVideoChatRoom}, dispatch] = useStateValue();
    const [[color1, color2], setColors] = useState([randomColors()[0], randomColors()[1]]);
    const [time, setTime] = useState(calcTime(roomData[room]?.timestamp));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const currentTimestamp = roomData && roomData[room] && roomData[room].timestamp;
    const [startBasis, setStartBasis] = useState(sidebarBasis);
    const roomRef = useRef(null);
    const authManager = useAuthManager();
    const navigate = useNavigate();

    const sender = (roomData[room].lastSender === user.username ? 'You':(roomData[room].lastSender || '')).substring(0, 5);
    const lastmsg = roomData[room].lastMessage;

    const pointerDown = () => {
        setStartBasis(roomRef.current.getBoundingClientRect().width);
    }

    const roomClick = (roomID) => {
        const width = roomRef.current.getBoundingClientRect().width;
        navigate(`/chat/${roomID}`, { replace:false });
        dispatch({ currentRoom: roomID });
        if (Math.abs(width-startBasis) < 10) {
            if (sidebarState === 'open' && currentRoom === roomID) {
                setSettingsOpen(!settingsOpen);
            }
            if (user.userPrefs?.auto_focus_textbox === true) {
                const mainTextbox = document.querySelector('div.textAreaInput[role="textbox"]');
                mainTextbox && mainTextbox.focus() 
            }             
        }
    }

    useEffect(() => {
        setTime(calcTime(roomData[room]?.timestamp));
        let timer = setInterval(() => {
            setTime(calcTime(roomData[room]?.timestamp));
        }, 60000);
        return () => {
            clearInterval(timer);
        }        
    }, [currentTimestamp])

    useEffect(() => {
        if (sidebarState === 'closed' || sidebarState === 'hidden') {
            setSettingsOpen(false);
        }
    }, [sidebarState])

    return (
        <>
        <div
            onPointerUp={() => {roomClick(room)}}
            onPointerDown={pointerDown}
            active={(room === currentRoom).toString()}
            className={`roomItemDiv ${room === currentVideoChatRoom ? 'currentVC':''}`}
            ref={roomRef}
        >
            <div 
                className='roomIconDiv' 
                style={{background: `radial-gradient(${color1}, ${color2})`}}
            >
                { roomData[room].unread > 0 && 
                    <div className='unreadIcon notificationIcon'>{roomData[room].unread}</div>
                }
                {   (roomData[room].video_chat_users || 0) > 0 &&
                    <div className='videoChatUserCount notificationIcon'>
                        <i className='fa-solid fa-video' />
                        {roomData[room].video_chat_users}
                    </div>
                }
                { room === currentVideoChatRoom &&
                    <div className='notificationIcon hangupIcon' onClick={() => {
                        dispatch({currentVideoChatRoom:null})
                    }}>
                        <i className="fa-solid fa-phone-slash"></i>
                    </div>
                }
                <h1 className='roomIconText'>
                    {roomData[room].room_name.split(' ').map(w => w[0]).join('').toUpperCase()}
                </h1>
            </div>
            <div className='roomItemInfo'>
                <div className='roomItemInfoHeader'>
                    <h4 className='roomItemName'>{roomData[room].room_name}</h4>
                    <h5 className='roomTimestamp'>{time}</h5>
                </div>
                { sender && lastmsg &&
                    <h5 className='roomLastMessage'>
                        <span style={{fontWeight: '800', color: 'black'}}>{sender}:</span>
                        <span style={{fontWeight: '500'}}> {lastmsg}</span>
                    </h5>
                }
            </div>
        </div> 
        <div className={`roomItemOptions${room === currentRoom && settingsOpen && sidebarState !== 'closed' ? ' open':''}`}>
            { roomData[room].owner === user.public_uid &&
                <ToolTip content='Room Settings' offsetV={-5}>
                    <i className='fa-solid fa-gear' />
                </ToolTip>
            }
            <ToolTip content='Personal Settings' offsetV={-5}>
                <i className='fa-solid fa-user-gear' />
            </ToolTip>
            { roomData[room].owner !== user.public_uid &&
                <ToolTip content='Leave Room' offsetV={-5}>
                    <i className='fa-solid fa-circle-xmark' />
                </ToolTip>
            }
            <ToolTip content='Pin Room' offsetV={-5}>
                <i className="fa-solid fa-thumbtack"></i>    
            </ToolTip>               
            { roomData[room].owner === user.public_uid &&
                <ToolTip content='Delete Room' offsetV={-5}>
                    <i className='fa-solid fa-trash' onClick={() => {
                        authManager.deleteRoom(room);
                    }}/>
                </ToolTip>
            }         
        </div>
        </>         
    )
}

function calcTime(timestamp) {
    if (!timestamp) return;
    const minutes = Math.round((new Date() - new Date(timestamp))/(1000*60));
    if (minutes>1140) {
        const days = Math.round(minutes/1140);
        return `${days} day${days>1 ? 's':''} ago`;
    } else if (minutes>60) {
        const hours = Math.round(minutes/60);
        return `${hours} hour${hours>1 ? 's':''} ago`;
    } else {
        return `${minutes} minute${minutes === 1 ? '':'s'} ago`;
    }
}