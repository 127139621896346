import './UserPrefs.css';
import FormField from '../Components/FormField';
import { useStateValue } from '../System/StateProvider';
import { useEffect, useRef, useState } from 'react';
import { useAuthManager } from '../System/API';

export default function UserPrefs() {
    const [{mobile, user}, dispatch] = useStateValue();
    const [changedPrefs, setChangedPrefs] = useState(user.userPrefs || {});
    const [changes, setChanges] = useState(false);
    const authManager = useAuthManager();
    const updatePrefs = useRef(null)

    const prefChange = (key, value) => {
        setChanges(true);
        setChangedPrefs({
            ...changedPrefs,
            [key]: value
        })
    }

    useEffect(() => {
        updatePrefs.current = () => {
            if (changes) {
                authManager.updateUserPrefs(changedPrefs);
            }
        }
    }, [changedPrefs, changes])    

    useEffect(() => {
        return () => {
            updatePrefs.current();
        }
    }, [])

    return (
        <div className='userPrefsContainer'>
            <FormField 
                label={'Play sound for outgoing messages'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('outgoing_sound', val)}
                val={changedPrefs.outgoing_sound}            
                //placeholder={'Username'}
                //buttonText={'Update'}
                //buttonClick={saveUsername}                
                //buttonPosition={mobile ? 'bottom':'attached'}
            />    
            <FormField 
                label={'Email address publicly visible'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('public_email', val)}
                val={changedPrefs.public_email}
            />
            <FormField 
                label={'Allow receive private messages'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('allow_private', val)}
                val={changedPrefs.allow_private}
            />
            <FormField 
                label={'Scroll to bottom on new message'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('scroll_on_new', val)}
                val={changedPrefs.scroll_on_new}
            />
            <FormField 
                label={'Auto open sidebar'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('auto_open_sidebar', val)}
                val={changedPrefs.auto_open_sidebar}
            />
            <FormField 
                label={'Auto hide sidebar'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('auto_close_sidebar', val)}
                val={changedPrefs.auto_close_sidebar}
            /> 
            <FormField 
                label={'Auto focus textbox'}
                type={'checkbox'}
                labelPosition='Right'
                change={(val) => prefChange('auto_focus_textbox', val)}
                val={changedPrefs.auto_focus_textbox}
            />                                                                      
        </div>
    )
}