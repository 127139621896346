import { useEffect, useReducer, useState, useRef } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import FormField from '../Components/FormField';
import { useAuthManager } from '../System/API';
import { useStateValue } from '../System/StateProvider';
import './CreateRoom.css';

const textLimitReducer = (state, value) => {
    if (value.length > 30) {
        return value.substring(0, 30);
    }
    return value
}

export default function CreateRoom(props) {
    const [{user}] = useStateValue();
    const [isPublic, setIsPublic] = useState('public_with_id');
    const [password, setPassword] = useReducer(textLimitReducer, '');
    const [roomName, setRoomName] = useReducer(textLimitReducer, '');
    const authManager = useAuthManager();
    const roomNameRef = useRef(null);

    const handleSubmit = () => {
        authManager.createRoom({
            room_name: roomName,
            password: password,
            is_public: isPublic,
            uid: user.public_uid
        })
        props.setState('');
    }

    useEffect(() => {
        if (roomNameRef.current) {
            roomNameRef.current.focus();
        }
    }, [roomNameRef])

    return (
        <div className='createRoomContainer'>
            <Form>
                <FormField 
                    label={'Room Name:'}
                    type={'text'}
                    val={roomName}
                    change={setRoomName}                
                    placeholder={'Room Name'}
                    ref={roomNameRef}
                />
                <label className='formLabel'>Room Privacy:</label>
                <div className='radioDiv'>
                    <Form.Radio
                        label='Public'
                        value='public'
                        checked={isPublic === 'public'}
                        onClick={() => {setIsPublic('public')}}
                    />
                    <p>This room can be joined by anyone and can be searched for by name.</p>
                </div>
                <div className='radioDiv'>
                    <Form.Radio
                        label='Public with ID'
                        value='public_with_id'
                        checked={isPublic === 'public_with_id'}
                        onClick={() => {setIsPublic('public_with_id')}}
                    />
                    <p>This room can be joined with anyone with the room ID, but cannot be searched for.</p>
                </div>
                <div className='radioDiv'>
                    <Form.Radio
                        label='Private'
                        value='private'
                        checked={isPublic === 'private'}
                        onClick={() => {setIsPublic('private')}}
                    />
                    <p>This room will require the ID and a password to enter.</p>
                </div>
                { isPublic === 'private' ?
                    <>
                        <label className='formLabel'>Password:</label>
                        <Form.Input 
                        icon={
                            <Icon link name={'eye slash' } onClick={(e) => {
                                const inp = e.target.previousElementSibling;
                                inp.type = inp.type === 'password' ? 'text':'password';
                                e.target.classList.toggle('slash');
                            }} />
                        }
                        className='inputIcon'
                        type={'password'}
                        value={password} 
                        placeholder='Password'
                        onChange={(e) => {setPassword(e.target.value)}}
                    /></>:null             
                }
                <div className='createButtonDiv'>
                    <Form.Button primary onClick={handleSubmit} disabled={!Boolean(roomName)}>Submit</Form.Button>
                </div>
            </Form>
        </div>    
    )
}