import './Settings.css';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function Settings() {
    const [menuStatus, setMenuStatus] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.split('/');
        setMenuStatus(path[path.length-1])
    }, [location])

    const changeSettingsPage = (page) => {
        navigate(`/settings/${page}`, { replace: false })
    }

    return ( 
        <div className='settingsContainerDiv'>
            <SettingsMenuHeader click={changeSettingsPage} status={menuStatus} />
            <div className='settingsBody'>
                <Outlet />
            </div>
        </div>            
    )
}

function SettingsMenuHeader(props) {
    return (
        <div className='settingsMenuHeader'>
            <div 
                className={`settingsMenuHeaderItem ${props.status === 'profile' ? 'active':''}`} 
                onClick={() => {props.click('profile')}}
            >
                <h4>Profile</h4>
            </div>
            <div 
                className={`settingsMenuHeaderItem ${props.status === 'user_prefs' ? 'active':''}`}
                onClick={() => {props.click('user_prefs')}}
            >
                <h4>User Prefs</h4>
            </div>
            {/* <div 
                className={`settingsMenuHeaderItem ${props.status === 'style' ? 'active':''}`} 
                onClick={() => {props.click('style')}}
            >
                <h4>Style</h4>
            </div> */}
        </div>
    )
}




function UserPrefs(props) {
    return (
        <h1>Hello!</h1>
    )
}

function Style(props) {
    return (
        <h1>Howdy!</h1>
    )
}

