import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStateValue } from "../System/StateProvider";
import './Sidebar.css';
import SidebarRouter from '../SidebarPages/SidebarRouter.js';
import { sidebarWidth, handleWidth, sidebarOpen, mobileSidebarOpen } from "../System/globalConstants";

const sidebarCSSVars =  {
    "--handleWidth": `${handleWidth}px`,
    "--sidebarWidth": `${sidebarWidth}px`
}  

export default function Sidebar(props) {
    const [{sidebarState, sidebarBasis, hideSidebar, sidebarPage, mobile, user}, dispatch] = useStateValue();
    const [lastHandleTouch, setLastHandleTouch] = useState(null);
    const sidebarOpenWidth = mobile ? mobileSidebarOpen:sidebarOpen;
    const [flexBasis, setFlexBasis] = useState(sidebarBasis);
    const [mousedown, setMousedown] = useState(false);
    const [mousepos, setMousepos] = useState(0);
    const [mouseStart, setMouseStart] = useState(0);
    const [preventDoubleClick, setPreventDoubleClick] = useState(false);
    const [resizing, setResizing] = useState(false);
    const sidebar = useRef(null);

    useEffect(() => {
        if (mousedown) {
            setResizing(true);
            sidebar.current.classList.add('resizing');
            window.onpointermove = (e) => {setMousepos(e.clientX)};
            window.ontouchmove = (e) => {setMousepos(e.changedTouches[0].clientX)};
            window.ontouchend = () => {setMousedown(false)};
            window.ontouchcancel = () => {setMousedown(false)};
            window.onpointerup = () => {setMousedown(false)};
        } else {
            setResizing(false); 
            sidebar.current.classList.remove('resizing');  
            window.onpointermove = null;
            window.ontouchmove = null;
            window.onpointerup = null;
            window.ontouchend = null;
            window.ontouchcancel = null;
            if (checkDoubleClick()) {
                if (sidebarState === 'closed') {
                    dispatch({
                        sidebarState: 'open',
                        sidebarBasis: handleWidth + sidebarWidth + sidebarOpenWidth
                    })
                } else {
                    dispatch({
                        sidebarState: 'closed'
                    })
                }
            } else if (sidebarState === 'closed' && Math.abs(mouseStart - mousepos) < 5 && user.userPrefs?.auto_open_sidebar === true) {
                dispatch({
                    sidebarState: 'open',
                    sidebarBasis: handleWidth + sidebarWidth + sidebarOpenWidth
                })
                setPreventDoubleClick(true);
                setTimeout(() => {
                    setPreventDoubleClick(false);
                }, 500);
            } else {
                dispatch({
                    sidebarBasis: flexBasis
                }) 
            }                        
        }
    }, [mousedown])

    useEffect(() => {
        if (resizing) {
            const change = mousepos - mouseStart;
            let newBasis = sidebarBasis + change;
            if (newBasis < handleWidth + sidebarWidth) {
                newBasis = handleWidth + sidebarWidth;
            }
            setFlexBasis(newBasis);            
        }
    }, [resizing, mousepos, mouseStart, sidebarBasis])

    useEffect(() => {
        if (sidebarPage) {
            setFlexBasis(450);
        } else if (hideSidebar) {
            setFlexBasis(0);
        } else {
            setFlexBasis(sidebarBasis);
        }
    }, [hideSidebar, sidebarPage, sidebarBasis])

    const checkDoubleClick = () => {
        if (sidebarState === 'open' && lastHandleTouch && Date.now()-lastHandleTouch < 250) {
            setLastHandleTouch(Date.now());
            return true
        } else if (sidebarState === 'closed' && lastHandleTouch && Date.now()-lastHandleTouch < 250 && user.userPrefs?.auto_open_sidebar === false) {
            setLastHandleTouch(Date.now());
            return true
        } else if (sidebarState === 'open' || user?.userPrefs?.auto_open_sidebar === false) {
            setLastHandleTouch(Date.now());
            return false;
        } else {
            return false;
        }
    }

    const mainContentFocus = () => {
        if (!hideSidebar && user.userPrefs?.auto_close_sidebar === true) {
            dispatch({
                sidebarState: 'closed'
            })    
        }        
    }

    return (
        <div id="mainBody" style={sidebarCSSVars} className='transp'>
            <div 
                id="sidebar" 
                style={{flexBasis: `${flexBasis}px`}}
                className={sidebarState}   
                ref={sidebar}
            >
                <div id="sidebarContent"
                    onPointerDown={(e) => {
                        if (!preventDoubleClick && !sidebarPage) {
                            setMouseStart(e.clientX);
                            setMousepos(e.clientX);
                            setMousedown(true);    
                        }
                    }}
                >
                    <SidebarRouter />
                </div>
                <div id="sidebarHandle" 
                    
                ></div>
            </div>
            <div id="mainContent"
                onPointerDown={mainContentFocus}
            >
                {props.children}
            </div>
        </div>
    )
}



