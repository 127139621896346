import { forwardRef } from 'react';
import './FormField.css';

const FormField = forwardRef((props, ref) => {

    const keydown = (e) => {
        if (props.type === 'text_with_button' && e.keyCode === 13) {
            props.buttonClick();
        }
    }

    return (
        <div className={`formFieldContainer ${props.labelPosition ? 'label'+props.labelPosition:''}`}>
            <h4 className='formFieldLabel'>{props.label}</h4>
            <div className={`formFieldInputDiv ${props.buttonPosition || ''} ${props.type}`}>
                <input 
                    className={`formFieldInput  ${props.buttonPosition || ''}`}
                    value={props.type !== 'checkbox' ? props.val:undefined}
                    checked={props.type === 'checkbox' ? props.val:undefined}
                    onChange={(e) => {
                        if (props.change) {
                            props.change(props.type === 'checkbox' ? e.target.checked:e.target.value)
                        }
                    }}
                    onKeyDown={keydown}
                    type={props.type === 'text_with_button' ? 'text':props.type}
                    placeholder={props.placeholder} 
                    disabled={props.disabled}
                    ref={ref}
                />
                {
                    props.type === 'text_with_button' &&
                    <button className={`formFieldInputButton ${props.buttonPosition || ''}`} onClick={props.buttonClick}>{props.buttonText}</button>
                }
            </div>
        </div>
    )    
})

export default FormField