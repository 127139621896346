import { useAudioManager } from "./Audio/AudioManager";
import { useAuthManager } from "./System/API";
import { Outlet, useParams } from 'react-router-dom';
import { useStateValue } from './System/StateProvider';
import { useEffect, useRef, useState } from 'react';
import { useSocket } from "./System/Socket.js";
import { useNavigate } from 'react-router-dom';
import Loading from "./Pages/Loading";


export default function DataLayer() {
    const [{user, messages, roomData, currentRoom, loading}, dispatch] = useStateValue();
    const audioManager = useAudioManager();
    const authManager = useAuthManager();
    const socketManager = useSocket();
    const navigate = useNavigate();
    const sendMessage = useRef(null);
    const { chatId } = useParams();
    const [disconnectTime, setDisconectTime] = useState(null);

    useEffect(() => {
        authManager.init();
        audioManager.init();
    }, []);

    useEffect(() => {
        if (!user) {
            socketManager.disconnect();
        } else {
            socketManager.initSocket(user);
        }
    }, [user])

    useEffect(() => {
        if (!socketManager.socket) return;
        socketManager.socket.on('connect', () => {
            if (disconnectTime && Date.now()-disconnectTime > 30000) {
                window.location.reload();
            }
            setDisconectTime(null);
            if (process.env.REACT_APP_ENV === 'dev ') {
                console.log(`Connected to socket: ${socketManager.socket.id}`)
            }
        });
        socketManager.socket.on('new_message', (id, msg) => {
            sendMessage.current([id, msg]);
        });        
        socketManager.socket.on('disconnect', () => {
            setDisconectTime(Date.now());
            if (process.env.REACT_APP_ENV === 'dev ') {
                console.log(`disconnected from socket`)
            }
        });        
        socketManager.socket.on('error', (err) => {
            if (process.env.REACT_APP_ENV === 'dev ') {
                console.log(err);
            }
        })  
        socketManager.socket.on('room_not_found', (roomID) => {
            console.log('That room does not exist.')
        })  
        socketManager.socket.on('room_deleted', (roomID) => {
            console.log('That room was deleted.')
        })                  
        socketManager.socket.on('video_chat_users', (usersObj) => {
            for (let room of Object.keys(usersObj)) {
                roomData[room].video_chat_users = usersObj[room]
            }
            dispatch({ roomData:roomData })
        })
        return () => {socketManager.socket.off()}
    }, [socketManager.socket])

    useEffect(() => {
        if (!user || loading) return;
        if (chatId && roomData && roomData[chatId]) {
            dispatch({
                currentRoom: chatId
            })
        } else if (chatId && !loading && (!roomData || !roomData[chatId])) {
            authManager.getRoomInfo({ roomId: chatId }).then(res => {
                if (typeof res === 'object') {
                    if (res.message === 'no_exist') {
                        navigate('/chat/noroom', { replace:true });
                    } else if (res.message === 'password_protected') {
                        navigate(`/chat/${chatId}/password`, { replace:true });
                    }
                }
            })
        }
    }, [user, roomData, chatId, loading])

    useEffect(() => {
        if (currentRoom) {
            roomData[currentRoom].unread = 0;
            navigate(`/chat/${currentRoom}`, { replace: true });
        }
    }, [currentRoom])

    useEffect(() => {
        sendMessage.current = ([msgID, msg]) => {
            audioManager.play(msg.user_id === user.public_uid ? 'send':'receive');
            if (msg.room !== currentRoom) {roomData[msg.room].unread = (roomData[msg.room].unread || 0)+1}
            messages[msg.room] = {...messages[msg.room], [msgID]: msg}
            roomData[msg.room] = {
                ...roomData[msg.room],
                lastMessage: msg.preview,
                timestamp: msg.timestamp,
                lastSender: msg.username
            }
            dispatch({
                messages:messages,
                roomData:roomData
            })
        }        
    }, [user, messages, roomData, currentRoom])

    if (loading) {
        return <Loading />
    } else {
        return <Outlet />
    }
}

