import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import { RouterProvider, useNavigate, useParams, createBrowserRouter } from 'react-router-dom';
import { StateProvider, useStateValue } from './System/StateProvider';
import Login from './Pages/Login.js';
import Settings from './Pages/Settings';
import CreateAccount from './Pages/CreateAccount.js';
import Root from './Root';
import Chat from './Pages/Chat';
import CreateRoom from './SidebarPages/CreateRoom';
import DataLayer from './DataLayer';
import { SocketProvider } from './System/Socket.js';
import Avatar from './Pages/Avatar';
import Profile from './Pages/Profile';
import UserPrefs from './Pages/UserPrefs';
import Loading from './Pages/Loading';
import FormField from './Components/FormField';
import { useAuthManager } from './System/API';
import VideoChat from './System/VideoChat';

const router = createBrowserRouter([
{
    path: '/',
    element: <DataLayer />,
    children: [
        {
            element: <Root />,
            children: [
                {
                    index: true,
                    element: <ChooseRoom />
                },               
                {
                    path: 'chat/',
                    children: [
                        {
                            path: 'noroom',
                            element: <NoRoom />
                        },                       
                        {
                            path: ':chatId',
                            children: [
                                {
                                    index: true,
                                    element: <Chat />,
                                },                                
                                {
                                    path: 'password',
                                    element: <RoomPassword />
                                }                                
                            ]
                        }
                    ]
                },                                    
                {
                    path: 'settings',
                    element: <Settings />,
                    children: [
                        {
                            index: true,
                            path: 'profile',
                            element: <Profile />
                        },
                        {
                            path: 'user_prefs',
                            element: <UserPrefs />
                        },
                        // {
                        //     path: 'style',
                        //     element: <Style />
                        // },
                        {
                            path: 'avatar',
                            element: <Avatar />
                        }
                    ]
                },
                {
                    path: 'create',
                    element: <CreateRoom />
                }
                       
            ]
        },
        {
            path: 'login/*',
            element: <Login />,
            errorPage: <ErrorPage />
        },
        {
            path: 'signup/*',
            element: <CreateAccount />,
            errorPage: <ErrorPage />
        },
        {
            path: 'loader',
            element: <Loading />,
            errorPage: <ErrorPage />
        }                  
    ]
}         
])

function NoRoom() {
    return (
        <div className='chooseRoomDiv'>
            <i className='fa-brands fa-weixin' style={{fontSize: '8em', margin: '0px'}} />
            <h2>Oops! Looks like that room doesn't exist</h2>
        </div>        
    )
}

function RoomPassword() {
    const [{mobile}, dispatch] = useStateValue();
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const { chatId } = useParams();
    const authManager = useAuthManager();
    const navigate = useNavigate();

    const roomLogin = () => {
        authManager.getRoomInfo({
            roomId: chatId,
            password: password
        }).then(res => {
            if (res.message) {
                setErrMsg('That password is incorrect. Please enter the password:')
            } else {
                navigate(`/chat/${chatId}`)
            }
        })
    }

    return (
        <div className='chooseRoomDiv'>
            <i className='fa-brands fa-weixin' style={{fontSize: '8em', margin: '0px'}} />            
            { errMsg ?
                <h2>{errMsg}</h2>:
                <h2>This room is password protected. Please enter the password:</h2>
            }
            <FormField 
                val={password}
                change={setPassword}
                type={'text_with_button'}
                placeholder={'Password'}
                buttonText={'Submit'}
                buttonClick={roomLogin}
                buttonPosition={mobile ? 'bottom':'attached'}
            />            
        </div>        
    )
}

function ChooseRoom() {
    return (
        <div className='chooseRoomDiv'>
            <i className='fa-brands fa-weixin' style={{fontSize: '8em', margin: '0px'}} />
            <h2>Select a conversation</h2>
        </div>
    )
}

function ErrorPage() {
    return (
        <h1>Something went wrong. Please refresh the page and try again. </h1>
    )
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <StateProvider>
        <SocketProvider>
            <RouterProvider router={router} />
        </SocketProvider>
    </StateProvider>
)