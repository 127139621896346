import './Message.css';
import { useState, useEffect } from "react";
import { defaultAvatar, replaceObj } from '../System/globalConstants.js'; 

export default function Message({ timestamp, from, msg, type, avatar, loaded, combine }) {
    const [time, setTime] = useState(calcTime());
    let message;
    if (type === 'text') {
        message = msg.split(/\r?\n/);
    } else if (type === 'rich_text') {
        message =  JSON.parse(msg);
    }

    function calcTime() {
        const minutes = Math.round((new Date() - new Date(timestamp))/(1000*60));
        if (minutes>1140) {
            const days = Math.round(minutes/1140);
            return `${days} day${days>1 ? 's':''} ago`;
        } else if (minutes>60) {
            const hours = Math.round(minutes/60);
            return `${hours} hour${hours>1 ? 's':''} ago`;
        } else {
            return `${minutes} minute${minutes === 1 ? '':'s'} ago`;
        }
    }
 
    useEffect(() => {
        let timer = setInterval(() => {
            setTime(calcTime())
        }, 60000);
        return () => {
            clearInterval(timer);
        }
    }, [])

    return (

        <div className={`comment ${combine ? 'combined':''}`}>
            { !combine &&             
                <div className='commentInfo'>
                    <div className='avatarContainer'>
                        <img 
                            className='commentAvatar' 
                            src={avatar === 'default' ? defaultAvatar:avatar} 
                            width={40}
                            height={40}    
                        />
                    </div>
                    <div className='commentInfoContent'>
                        <div className='commentAuthor'>
                            {from}  
                        </div>
                        <div className='commentMetadata'>
                            {time}
                        </div>
                    </div>
                </div>
            }
            <div className='commentContent'>
                {
                    type === 'text' ?
                    message.map((r, index) => {
                        return ( 
                            <p key={`messageline-${index}`} className='commentLine'>
                                {r.includes('.gif') ? <img src={r} width={300}/>:r}
                            </p>
                        )
                    }):
                    message.map((elem, index) => {
                        return parseJsonDom(elem, index, loaded);
                    })
                }
            </div>            
        </div>
    )
}

const messageParse = (newMsg) => {
    for (let key of Object.keys(replaceObj)) {
        newMsg = newMsg.replaceAll(key, replaceObj[key]);
        const regex = new RegExp('/rolld*([0-9]+)*').exec(newMsg);
        if (regex) {
            const sides = regex[1] ? parseInt(regex[1]) : 6;
            const rolled = Math.ceil(Math.random() * sides);
            newMsg = `Rolls a ${sides !== 6 ? sides + " sided" : ''} die and gets ${rolled}`;
        }
    }
    return newMsg
}

const parseJsonDom = (child, index, loaded) => {
    const attributes = {};
    for (let key of Object.keys(child)) {
        if (['style', 'text', 'url', 'children'].includes(key)) {
            continue;
        } else {
            attributes[key] = child[key]
        }        
    }
    switch(child.type) {
        case 'p':
            return (
                <p key={index} style={child.style} {...attributes}>
                    { child.children?.length > 0 && 
                        child.children.map((chld, indx) => {
                            return (parseJsonDom(chld, indx, loaded))
                        })
                    }                    
                </p>
            )
        case 'paragraph':
            return (
                <p key={index} style={child.style} {...attributes}>
                    { child.children?.length > 0 && 
                        child.children.map((chld, indx) => {
                            return (parseJsonDom(chld, indx, loaded))
                        })
                    }                    
                </p>
            )            
        case 'code':
            return (
                <pre key={index} style={{
                    ...child.style,
                    backgroundColor: 'lightgray',
                    paddingLeft: '5px',
                    borderLeft: '1px solid grey'
                }} {...attributes}>
                    <code>
                        { child.children?.length > 0 && 
                            child.children.map((chld, indx) => {
                                return (parseJsonDom(chld, indx, loaded))
                            })
                        }
                    </code>
                </pre>
            )
        case 'image':
            return (
                <img 
                    key={index} 
                    src={decodeURIComponent(child.url)} 
                    {...attributes} 
                    width={300}
                    onLoad={loaded}
                ></img>
            )
        case 'ul':
            return (
                <ul key={index} style={child.style} {...attributes}>
                    { child.children?.length > 0 && 
                        child.children.map((chld, indx) => {
                            return (parseJsonDom(chld, indx, loaded))
                        })
                    }                    
                </ul>
            )
        case 'li':
            return (
                <li key={index} style={child.style} {...attributes}>
                    { child.children?.length > 0 && 
                        child.children.map((chld, indx) => {
                            return (parseJsonDom(chld, indx, loaded))
                        })
                    }                    
                </li>
            )
        case 'ol':
            return (
                <ol key={index} style={child.style} {...attributes}>
                    { child.children?.length > 0 && 
                        child.children.map((chld, indx) => {
                            return (parseJsonDom(chld, indx, loaded))
                        })
                    }                    
                </ol>
            )
        case 'text':
            return (
                <span key={index} style={child.style} {...attributes}>
                    {messageParse(decodeURIComponent(child.text))}                    
                </span>
            )
        case 'link':
            let url = decodeURIComponent(child.text);
            if (!url.startsWith('http')) {
                url = 'https://' + url;
            }
            return (
                <a key={index} style={child.style} {...attributes} href={url} target='_blank'>
                    {url}                   
                </a>
            )
        case 'sup':
            return (
                <sup key={index} style={child.style} {...attributes}>
                    {decodeURIComponent(child.text)}                    
                </sup>
            )
        case 'sub':
            return (
                <sub key={index} style={child.style} {...attributes}>
                    {decodeURIComponent(child.text)}                   
                </sub>
            )
        default:
            return (
                <span key={index} style={child.style} {...attributes}>
                    {messageParse(decodeURIComponent(child.text))}
                </span>
            )
    }
}

