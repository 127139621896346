import './Loading.css';

export default function Loading() {
    const factor = 0.85;
    const times = 6;
    const borderColor = [50, 150, 200].map(r => r*(factor**times));
    return (
        <div className='loadingContainer'>
            <div className='loadingOuterDiv'>
                <LoadingDiv times={6} col={borderColor} fac={factor} />
            </div>
        </div>
    )
}

function LoadingDiv({times, col, fac}) {
    return (
        <div className={`loadingDiv ${times}`} style={{borderColor: `rgb(${col})`}} >
            { times > 0 &&
                <LoadingDiv times={times-1} col={col.map(r => r/fac)} fac={fac}/>
            }
        </div>
    )
}