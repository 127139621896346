import { useCallback, useEffect, useRef, useState } from 'react';
import { useStateValue } from '../System/StateProvider';
import imrcv from './imrcv.wav';
import imsend from './imsend.wav';

const emptyAudio = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"

export default function AudioElement() {
    return (
        <>
        <audio 
            id="sendElement" 
            src={imsend}    
        />
        <audio 
            id="receiveElement" 
            src={imrcv}    
        />
        </>
    )
}


export function useAudioManager() {
    const [{alertVolume, user}, dispatch] = useStateValue();
    const volRef = useRef(alertVolume);

    useEffect(() => {
        volRef.current = alertVolume;
        window.localStorage.setItem('volume', alertVolume);
    }, [alertVolume])

    const AudioManager = {
        init: () => {
            const clickListener = () => {
                const sendElem = document.querySelector('audio#sendElement');
                const receiveElem = document.querySelector('audio#receiveElement');
                sendElem.volume = 0;
                receiveElem.volume = 0;
                sendElem?.play().then(() => {
                    receiveElem.play().then(() => {
                        window.removeEventListener('click', clickListener);
                    })
                });
            }
            window.addEventListener('click', clickListener);
        },
        play: (playObj) => {
            if (playObj === 'send' && user.userPrefs?.outgoing_sound === false) return;
            const audioElem = document.querySelector(`audio#${playObj}Element`);
            if (audioElem) {
                audioElem.volume = volRef.current/100;
                audioElem.play().catch(error => {
                    console.warn(error)
                })
            }  
        }
    }

    return AudioManager
}

