

export const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align, margin: 0 }
    switch (element.type) {
        case 'code':
            return (
                <pre
                    style={{
                        ...style,
                        backgroundColor: 'lightgray',
                        paddingLeft: '5px',
                        borderLeft: '1px solid grey'
                    }}
                    {...attributes}
                >
                    <code>
                        {children}
                    </code>
                </pre>
            )
        case 'image':
            return (
                <div {...attributes} contentEditable={false} className='imageElementContainer'>
                    {children}
                    <img src={element.url} height={150}/>
                </div>
            )
        case 'ul':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'li':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'ol':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

export const Leaf = ({ attributes, children, leaf}) => {
    const styleObj = {};
    const styleProps = Object.keys(leaf).filter(prop => {
        return prop.includes('$style_')
    }).map(prop => {
        styleObj[prop.split('$style_')[1]] = leaf[prop];
        return 
    })
    const style = styleObj;
    switch(leaf.type) {
        case 'text':
            return (
                <span style={style} {...attributes}>
                    {children}
                </span>
            )
        case 'link':
            const href = children.props.text.text;
            return (
                <a style={style} href={href} {...attributes}>
                    {children}
                </a>
            )
        case 'sup':
            return (
                <sup style={style} {...attributes}>
                    {children}
                </sup>
            )
        case 'sub':
            return (
                <sub style={style} {...attributes}>
                    {children}
                </sub>
            )
        default:
            return (
                <span style={style} {...attributes}>
                    {children}
                </span>
            )            
    }
}

