//https://github.com/Dirvann/webrtc-video-conference-simple-peer
import { useEffect, useRef, useState } from 'react';
import { useSocket } from './Socket';
import { useStateValue } from './StateProvider';
import { createPortal } from 'react-dom';
import './VideoChat.css'
import useDynamicRefs from './DynamicRefs';
import SimplePeer from 'simple-peer';
import * as process from 'process';
window.global = window;
window.process = process;
window.Buffer = [];


const configuration = {
    // Using From https://www.metered.ca/tools/openrelay/
    "iceServers": [
        {
            urls: "stun:openrelay.metered.ca:80"
        },
        {
            urls: "turn:openrelay.metered.ca:80",
            username: "openrelayproject",
            credential: "openrelayproject"
        },
        {
            urls: "turn:openrelay.metered.ca:443",
            username: "openrelayproject",
            credential: "openrelayproject"
        },
        {
            urls: "turn:openrelay.metered.ca:443?transport=tcp",
            username: "openrelayproject",
            credential: "openrelayproject"
        }
    ]
}


const VideoChat = ({ callType, returnAPI }) => {
    const [{ user, currentRoom, sidebarBasis, sidebarState, hideSidebar }, dispatch] = useStateValue();
    const socketManager = useSocket();
    const roomName = currentRoom;
    const localStream = useRef(null);
    const localVideo = useRef(null);
    const peers = useRef({});
    const [connections, setConnections] = useState([]);
    const videoContainer = useRef(null);
    const [getRef, setRef] = useDynamicRefs();

    let constraints = {
        audio: {
            echoCancellation: true,
            noiseSuppression: true,
            suppressLocalAudioPlayback: true
        },
        video: {
            width: {
                max: 500
            },
            height: {
                max: 500
            },
            facingMode: {
                ideal: 'user'
            }
        }
    }

    const recalculateVideoSizes = () => {
        if (!videoContainer.current) return;
        const rect = videoContainer.current.getBoundingClientRect();
        const dimensions = {
            width: rect.width,
            height: rect.height,
            ratio: rect.width / rect.height
        }
        if (Object.keys(peers.current).length > 0 && Object.values(peers.current).every(peer => { return peer.aspectRatio})) {
            const rows = calcRows(peers.current, dimensions.ratio);
            console.log(rows)
            setConnections(rows);
        } else {
            setConnections(null);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', recalculateVideoSizes);
        return () => {
            window.removeEventListener('resize', recalculateVideoSizes);
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            recalculateVideoSizes();
        }, 500)
    }, [sidebarBasis, sidebarState, hideSidebar])

    useEffect(() => {
        if (returnAPI) {
            returnAPI(api)
        }
    }, [returnAPI])

    useEffect(() => {
        return () => {
            removeLocalStream();
        }
    }, [])

    useEffect(() => {
        if (!socketManager.socket) return;
        navigator.mediaDevices?.enumerateDevices().then((devices) => {
            if (!devices.some(device => device.kind === 'videoinput') || callType === 'audio') {
                constraints.video = false
            }
            if (!devices.some(device => device.kind === 'audioinput')) {
                alert('You must have a microphone to join a call')
                return
            }
            navigator.mediaDevices?.getUserMedia(constraints).then(stream => {
                if (callType !== 'audio') {
                    localVideo.current.srcObject = stream;
                }
                localStream.current = stream;

                socketManager.socket.emit('vc-join', {
                    roomName: roomName,
                    username: user.username,
                    type: callType,
                    avatar: user.avatar,
                    aspectRatio: callType === 'video' ? stream.getVideoTracks()[0].getSettings().aspectRatio:1
                });

                socketManager.socket.on('vc-initReceive', (data) => {
                    addPeer(data, false)
                    socketManager.socket.emit('vc-initSend', data.socket_id, roomName)
                })

                socketManager.socket.on('vc-initSend', (data) => {
                    addPeer(data, true)
                })

                socketManager.socket.on('vc-removePeer', socket_id => {
                    removePeer(socket_id)
                })

                socketManager.socket.on('vc-disconnect', () => {
                    for (let socket_id in peers.current) {
                        removePeer(socket_id)
                    }
                })

                socketManager.socket.on('vc-signal', data => {
                    if (data.socket_id === socketManager.socket.id) return;
                    if (peers.current[data.socket_id] && !peers.current[data.socket_id].peer.destroyed) {
                        peers.current[data.socket_id].peer.signal(data.signal)
                    } else {
                        delete peers.current[data.socket_id]
                    }
                })

                dispatch({ currentVideoChatRoom: roomName });
            }).catch(e => alert(`getusermedia error ${e.name}`))
        })

        return () => {
            socketManager.socket.off('vc-initReceive')
            socketManager.socket.off('vc-initSend')
            socketManager.socket.off('vc-removePeer')
            socketManager.socket.off('vc-disconnect')
            socketManager.socket.off('vc-signal')
            socketManager.socket.emit('vd-disconnect', roomName);
        }
    }, [socketManager.socket])

    const addPeer = (socketData, am_initiator) => {
        peers.current[socketData.socket_id] = {
            peer: new SimplePeer({
                initiator: am_initiator,
                stream: localStream.current,
                config: configuration
            }),
            ...socketData
        }

        peers.current[socketData.socket_id].peer.on('signal', (data) => {
            socketManager.socket.emit('vc-signal', {
                signal: data,
                socket_id: socketData.socket_id
            }, roomName)
        })

        peers.current[socketData.socket_id].peer.on('stream', (stream) => {
            setRef(socketData.socket_id + '_stream').current = stream;
            for (let id of Object.keys(peers.current)) {
                getRef(id).current.srcObject = getRef(id + '_stream').current;
            }
        })
        recalculateVideoSizes()
    }

    const removePeer = (socket_id) => {
        let videoEl = document.getElementById(socket_id)
        if (videoEl) {
            const tracks = videoEl.srcObject.getTracks();
            tracks.forEach(function (track) {
                track.stop()
            })
            videoEl.srcObject = null
            videoEl.remove();
        }
        if (peers.current[socket_id]) peers.current[socket_id].peer.destroy()
        delete peers.current[socket_id]
        recalculateVideoSizes()
        getRef(socket_id).current = null;
        getRef(socket_id + '_stream').current = null;
    }

    const openPictureMode = (el) => {
        el.requestPictureInPicture()
    }

    // Switches the camera between user and environment. It will just enable the camera 2 cameras not supported.
    function switchMedia() {
        if (constraints.video.facingMode.ideal === 'user') {
            constraints.video.facingMode.ideal = 'environment'
        } else {
            constraints.video.facingMode.ideal = 'user'
        }
        const tracks = localStream.current.getTracks();
        tracks.forEach(function (track) {
            track.stop()
        })
        localVideo.current.srcObject = null
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            for (let socket_id in peers.current) {
                for (let index in peers.current[socket_id].peer.streams[0].getTracks()) {
                    for (let index2 in stream.getTracks()) {
                        if (peers.current[socket_id].peer.streams[0].getTracks()[index].kind === stream.getTracks()[index2].kind) {
                            peers.current[socket_id].peer.replaceTrack(peers.current[socket_id].streams[0].peer.getTracks()[index], stream.getTracks()[index2], peers.current[socket_id].peer.streams[0])
                            break;
                        }
                    }
                }
            }
            localStream.current = stream
            localVideo.current.srcObject = stream
        })
    }

    // Enable screen share
    async function setScreen(sharing) {
        const stopTracks = () => {
            const tracks = localStream.current.getTracks();
            tracks.forEach(function (track) {
                track.stop()
            })
        }

        const handleStream = (stream) => {
            stopTracks();
            for (let socket_id in peers.current) {
                for (let index in peers.current[socket_id].peer.streams[0].getTracks()) {
                    for (let index2 in stream.getTracks()) {
                        if (peers.current[socket_id].peer.streams[0].getTracks()[index].kind === stream.getTracks()[index2].kind) {
                            peers.current[socket_id].peer.replaceTrack(peers.current[socket_id].peer.streams[0].getTracks()[index], stream.getTracks()[index2], peers.current[socket_id].peer.streams[0])
                            break;
                        }
                    }
                }
            }
            localStream.current = stream
            localVideo.current.srcObject = localStream.current
            return !sharing
        }

        if (sharing) {
            return navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    return handleStream(stream)
                }).catch((err) => {
                    console.warn("An unknown error occurred");
                    return sharing;
                })
        } else {
            return navigator.mediaDevices.getDisplayMedia(constraints)
                .then((stream) => {
                    return handleStream(stream)
                }).catch((err) => {
                    console.warn("Screen share cancelled");
                    return sharing;
                })
        }
    }

    const api = {
        removeLocalStream,
        toggleMute,
        toggleVid,
        setScreen,
        switchMedia,
        roomName
    }

    // Disables and removes the local stream and all the connections to other peers.
    function removeLocalStream() {
        socketManager.socket.emit('vc-disconnect', roomName);
        if (localStream.current) {
            const tracks = localStream.current.getTracks();
            tracks.forEach(function (track) {
                track.stop()
            })
            if (localVideo.current) {
                localVideo.current.srcObject = null
            }
        }
        for (let socket_id in peers.current) {
            removePeer(socket_id)
        }
        dispatch({ currentVideoChatRoom: null });

    }

    function toggleMute() {
        for (let index in localStream.current.getAudioTracks()) {
            localStream.current.getAudioTracks()[index].enabled = !localStream.current.getAudioTracks()[index].enabled;
            return localStream.current.getAudioTracks()[index].enabled ? true : false;
        }
    }

    function toggleVid() {
        for (let index in localStream.current.getVideoTracks()) {
            localStream.current.getVideoTracks()[index].enabled = !localStream.current.getVideoTracks()[index].enabled
            return localStream.current.getVideoTracks()[index].enabled ? true : false
        }
    }

    return (
        <>
            <div className='videosContainer' ref={videoContainer}>
                { connections && 
                    Object.keys(connections).map(row => {
                        return (
                            <div className='videoChatRow'
                                key={row}
                                style={{
                                    height: ((1/Object.keys(connections).length)*100).toFixed(2)+'%'
                                }}
                            >
                            {
                            Object.values(connections[row].items).map(item => {
                                const id = item[1];
                                const currentAR = item[0]; 
                                const totalAR = connections[row].total;
                                if (peers.current[id].type === 'video') {
                                    return (
                                        <div
                                            className='videoChatStreamContainer'
                                            key={id}
                                            style={{
                                                width: ((currentAR/totalAR)*100).toFixed(2)+'%',
                                                height: '100%'
                                            }}                                            
                                        >
                                            <video
                                                ref={setRef(id)}
                                                id={id}
                                                playsInline={false}
                                                autoPlay={true}
                                                className='vid'
                                            />
                                            <h5 className='videoChatUsername'>{peers.current[id].username}</h5>
                                            <div className='videoChatStreamControls'>
                                                <i className="fa-solid fa-up-right-and-down-left-from-center"
                                                    onPointerDown={(e) => openPictureMode(e.target.closest('.videoChatStreamContainer').children[0])}
                                                />
                                            </div>
                                        </div>
                                    )
                                } else if (peers.current[id].type === 'audio') {
                                    return (
                                        <div
                                            className='audioChatStreamContainer'
                                            key={id}
                                            style={{
                                                width: ((currentAR/totalAR)*100).toFixed(2)+'%',
                                                height: '100%'
                                            }}                                             
                                        >
                                            <img
                                                src={peers.current[id].avatar}
                                                width={100}
                                                height={100}
                                            />
                                            <audio 
                                                ref={setRef(id)}
                                                id={id}
                                                autoPlay={true}
                                            />
                                            <h5 className='videoChatUsername audio'>{peers.current[id].username}</h5>
                                        </div>
                                    )
                                }
                            })
                            }   
                            </div>
                        )
                    })
                }
                {callType !== 'audio' &&
                    createPortal(
                        <div
                            className='localVideoContainer'
                        >
                            <video
                                id="localVideo"
                                className="vid"
                                autoPlay
                                muted
                                ref={localVideo}
                            />
                        </div>,
                        document.querySelector('div.chatUpperToolbar')
                    )
                }
            </div>
        </>
    );
};

export default VideoChat;



const calcRows = (peers, vidDimensionsRatio) => {
    console.log(peers, vidDimensionsRatio);
    const peerArray = Object.keys(peers).map((peer) => {
        return [peers[peer].aspectRatio, peer];
    }).sort((a, b) => {
        return b[0] - a[0]
    })
    const aspectSum = peerArray.reduce((total, num) => { return total + num[0] }, 0);

    let rowObj = {};
    let rows = Math.round(Math.sqrt(aspectSum / vidDimensionsRatio)) || 1;
    let ideal = aspectSum / rows;
    for (let i = 0; i < rows; i++) {
        rowObj[i] = {
            items: {},
            total: 0
        }
    }

    const getMinMax = (max) => {
        let val = max ? 0 : Infinity;
        let index;
        for (let row of Object.keys(rowObj)) {
            if ((max && rowObj[row].total > val) || (!max && rowObj[row].total < val)) {
                val = rowObj[row].total;
                index = row;
            }
        }
        return rowObj[index]
    }

    for (let peerData of peerArray) {
        const row = getMinMax();
        row.items[peerData[1]] = peerData;
        row.total += peerData[0];
        row.variance = Math.abs(row.total - ideal);
    }

    const calcRowInfo = () => {
        for (let row of Object.keys(rowObj)) {
            rowObj[row].total = Object.values(rowObj[row].items).reduce((total, value) => { return total + value[0] }, 0)
            rowObj[row].variance = Math.abs(rowObj[row].total - ideal);
        }
    }

    calcRowInfo()

    let swapped = false;
    do {
        let currentVariance = findVariance(Object.values(rowObj).map(row => row.total))
        swapped = false;
        const max = { ...getMinMax(true) };
        const min = { ...getMinMax() };
        const furthest = Math.abs(max.total - ideal) > Math.abs(min.total - ideal) ? max : min;
        const sortedRows = Object.keys(rowObj).map(row => { return rowObj[row] }).sort((a, b) => {
            return furthest === max ? (a.total - b.total) : (b.total - a.total)
        })
        const currentAspectRatios = sortedRows.map(row => row.total);
        let bestMatch = {
            variance: currentVariance
        };

        for (let i = 0; i < sortedRows.length - 1; i++) {
            for (let item of Object.values(sortedRows[i].items)) {
                innerLoop:
                for (let currentItem of Object.values(furthest.items)) {
                    if ((furthest === max && item[0] >= currentItem[0]) ||
                        (furthest === min && item[0] <= currentItem[0])
                    ) continue innerLoop;
                    let diff = currentItem[0] - item[0];
                    let newRatios = [...currentAspectRatios];
                    newRatios[i] += diff;
                    newRatios[newRatios.length - 1] -= diff;
                    let newVariance = findVariance(newRatios);
                    if (newVariance < bestMatch.variance) {
                        bestMatch = {
                            from: {
                                rowIndex: String(i),
                                val: item[1]
                            },
                            to: {
                                rowIndex: String(sortedRows.length - 1),
                                val: currentItem[1]
                            },
                            variance: newVariance
                        }
                        swapped = true;
                    }
                }
            }
        }
        if (swapped === true) {
            sortedRows[bestMatch.to.rowIndex].items[bestMatch.from.val] = sortedRows[bestMatch.from.rowIndex].items[bestMatch.from.val];
            sortedRows[bestMatch.from.rowIndex].items[bestMatch.to.val] = sortedRows[bestMatch.to.rowIndex].items[bestMatch.to.val];
            delete sortedRows[bestMatch.from.rowIndex].items[bestMatch.from.val];
            delete sortedRows[bestMatch.to.rowIndex].items[bestMatch.to.val];
            calcRowInfo();
        }
    } while (swapped)
    return rowObj
}

const findVariance = (arr = []) => {
    if (!arr.length) {
        return 0;
    };
    const sum = arr.reduce((acc, val) => acc + val);
    const { length: num } = arr;
    const median = sum / num;
    let variance = 0;
    arr.forEach(num => {
        variance += ((num - median) * (num - median));
    });
    variance /= num;
    return variance;
}

