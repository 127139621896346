import "./Login.css";
import { Message, Form, Input, Icon } from "semantic-ui-react";
import { Link, useMatches, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from "react";
import { useStateValue } from "../System/StateProvider";
import { useAuthManager } from "../System/API.js";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isValid, setValid] = useState(false);
    const [{signedIn}] = useStateValue();
    const navigate = useNavigate();
    const authManager = useAuthManager();
    const match = useMatches()
    const path = match[match.length-1].pathname.split('login')[1];


    const checkValid = useCallback(() => {
        const emailCheck = new RegExp('^([a-zA-Z0-9._:$!%-]+)@([a-zA-Z0-9.-]+)[.]([a-zA-Z]+)$').test(email);
        const pwCheck = new RegExp('^(?=.*[A-Za-z])(?=.*[0-9]).{6,}$').test(password);
        setValid(emailCheck && pwCheck);  
    }, [email, password])
    
    useEffect(() => { 
        checkValid();
    }, [email, password])
    
    useEffect(() => { 
        if (signedIn) {
            navigate(path || '/', {replace: true})
        }
    }, [signedIn])
    
    function doSignIn(e) {
        e.preventDefault();
        setErrorMsg(null);
        authManager.signIn(email, password)
            .catch((error) => {
                setErrorMsg(error.code); 
            })
    }    

    return (
        <div className="loginPageContainer">
            <div className="loginContainer">
            <Message attached>
                <Message.Header>
                    Welcome to 
                    <h1 className="titleString">Bien<span id={"bolder"}>Chats</span>&nbsp;</h1>
                </Message.Header>    
                <Message.Content>Fill out the form below to log-in.</Message.Content>
            </Message>                 
            <Form className='loginForm attached fluid segment' onSubmit={doSignIn} error={Boolean(errorMsg)}>
                <Form.Field required={true}>
                    <label>Email:</label> 
                    <Input value={email} onChange={(e) => {setEmail(e.target.value)}} type="text" placeholder='Email' />
                </Form.Field>
                <Form.Field required={true}>
                    <label>Password:</label>
                    <Input 
                        icon={
                            <Icon link name={'eye' } onClick={(e) => {
                                const inp = e.target.previousElementSibling;
                                inp.type = inp.type === 'password' ? 'text':'password';
                                e.target.classList.toggle('slash');
                            }} />
                        }
                        className='inputIcon'
                        type={'password'}
                        value={password} 
                        placeholder='Password'
                        onChange={(e) => {setPassword(e.target.value)}}
                    />
                </Form.Field>
                <Message
                    error
                    header={'Error:'}
                    content={errorMsg}
                />
                <Form.Button content='Login' disabled={!isValid} primary/>
            </Form>
            <Message attached='bottom' warning>
                <Icon name='exclamation circle' />
                Don't have an account yet?&nbsp;<Link to={'/signup'+path}>Create one here</Link>.
            </Message>
            </div>
        </div>
    )
}

